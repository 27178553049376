import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueRouter from "vue-router";
import IaaiService from "@/IaaiService";

const jquery = require('../node_modules/jquery/dist/jquery.min')
window.$ = window.jQuery = jquery

const signalr = require('../node_modules/signalr/jquery.signalR.min');
window.signalr = signalr

Vue.config.productionTip = false;

const routes = [
    {path: '/', component: App}
]

const router = new VueRouter({
    mode: 'history',
    routes
})

Vue.use(VueRouter)

Vue.prototype.$iaaiService = new IaaiService()

function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

new Vue({
    store,
    router,
    render: h => h(App),
    beforeCreate() {
        const bearerToken = this.$route.query.token || getCookie('token');
        this.$iaaiService.init(bearerToken);
        document.cookie = `token=${bearerToken}`;
    }
}).$mount('#app')
