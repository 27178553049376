<template>
  <div class="auction-container__nav">
    <div class="auction-container__nav-item" :class="{'auction-container__nav-item--active': !isVisible}" @click="toggleVisible" data-visible="0">
      <span class="icon icon--xs"><svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path
          d="M31.687 3.512a1.065 1.065 0 0 1 0 1.509L21.02 15.689a1.068 1.068 0 0 1-1.508 0l-3.2-3.201a1.065 1.065 0 0 1 0-1.509L26.98.313a1.065 1.065 0 0 1 1.508 0l3.2 3.199zM26.34 29.437a1.109 1.109 0 0 1 0 1.572l-3.333 3.332a1.107 1.107 0 0 1-1.57 0l-7.779-7.777a1.112 1.112 0 0 1 0-1.572l3.334-3.332a1.106 1.106 0 0 1 1.57 0l7.778 7.777zm-7.985 4.453a1.06 1.06 0 0 1 0 1.502L6.057 47.69a1.063 1.063 0 0 1-1.502 0L.31 43.443a1.063 1.063 0 0 1 0-1.501l12.298-12.299a1.064 1.064 0 0 1 1.5 0l4.247 4.247zM39.69 15.885a1.054 1.054 0 0 1 0 1.494l-6.337 6.337a1.055 1.055 0 0 1-1.493 0l-.929-.928-3.569 3.57a1.057 1.057 0 0 1-1.495 0l-4.223-4.226a1.054 1.054 0 0 1 0-1.494l3.569-3.569-.928-.928a1.054 1.054 0 0 1 0-1.494l6.337-6.337a1.055 1.055 0 0 1 1.493 0l7.575 7.575zm7.997 3.628a1.065 1.065 0 0 1 0 1.509L37.022 31.689a1.068 1.068 0 0 1-1.509 0l-3.2-3.203a1.062 1.062 0 0 1 0-1.506L42.98 16.313a1.065 1.065 0 0 1 1.508 0l3.2 3.2z"
          fill-rule="evenodd"></path></svg></span>
      On The Block
    </div>
    <div class="auction-container__nav-item" :class="{'auction-container__nav-item--active': isVisible}" @click="toggleVisible" data-visible="1">
      <span class="icon icon--xs">
         <svg viewBox="0 0 48 47" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M44.138 3.31h-4.966v4.966h4.966V3.31zm-8.276-1.379C35.862.865 36.727 0 37.793 0h8.276C47.135 0 48 .865 48 1.931v8.276a1.931 1.931 0 0 1-1.931 1.93h-8.276a1.931 1.931 0 0 1-1.93-1.93V1.93zm8.276 36.525h-4.966v4.414h4.966v-4.414zm-8.276-1.931c0-1.067.865-1.931 1.931-1.931h8.276c1.066 0 1.931.864 1.931 1.93v8.277a1.931 1.931 0 0 1-1.931 1.93h-8.276a1.931 1.931 0 0 1-1.93-1.93v-8.276zm8.276-15.008h-4.966v4.414h4.966v-4.414zm-8.276-1.93c0-1.067.865-1.932 1.931-1.932h8.276c1.066 0 1.931.865 1.931 1.931v8.276a1.931 1.931 0 0 1-1.931 1.931h-8.276a1.931 1.931 0 0 1-1.93-1.93v-8.277zM2.76 8.826h24.157a2.759 2.759 0 0 0 0-5.517H2.759a2.759 2.759 0 0 0 0 5.518zm0 17.656h24.157a2.759 2.759 0 0 0 0-5.517H2.759a2.759 2.759 0 0 0 0 5.517zm26.915 14.896a2.759 2.759 0 0 1-2.758 2.759H2.759a2.759 2.759 0 0 1 0-5.517h24.157a2.759 2.759 0 0 1 2.758 2.758z"
              fill-rule="evenodd"></path>
        </svg>
      </span>
      Run List
    </div>
  </div>
</template>

<script>
export default {
  name: "NavMobile",
  props: ['isVisible', 'changeVisible'],
  methods: {
    toggleVisible(e) {
      const visibleValue = e.target.dataset.visible;

      if(visibleValue) {
        this.changeVisible(parseInt(visibleValue));
      }
    }
  }
}
</script>

<style lang="scss">
.auction-container {

  &__nav {
    display: flex;

    @media screen and (min-width: 768px) {
      display: none;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      color: rgba(255, 255, 255, 0.7);
      background: #434343;
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
      padding: .5rem 0;
      border-top: 3px solid rgba(255, 255, 255, .0001);
      transition: all .2s ease-in-out;
      $item: &;

      &--active {
        color: #fff;
        background: #666;
        border-top-color: #c90107;

        &#{$item} {
          .icon {
            fill: #fff;
          }
        }
      }

      .icon {
        fill: rgba(255, 255, 255, .7);
        margin-right: 1rem;
        pointer-events: none;
      }
    }
  }
}
</style>