export const SET_AUCTIONS = 'SET_AUCTIONS';
export const ADD_JOIN_AUCTION = 'JOIN_AUCTION';
export const REMOVE_JOIN_AUCTION = 'REMOVE_JOIN_AUCTION';
export const SET_AUCTION_ITEM = 'SET_AUCTION_ITEM';
export const SHOW_POPUP = 'SHOW_POPUP';
export const SET_LOT = 'SET_LOT';
export const AUCTION_START = 'AUCTION_IS_STARTED';
export const UPDATE_BID_HISTORY = 'UPDATE_BID_HISTORY';
export const UPDATE_CURRENT_HIGH_BID_LOT = 'UPDATE_CURRENT_HIGH_BID_LOT';
export const UPDATE_CURRENT_BID_LOT = 'UPDATE_CURRENT_BID_LOT';
export const UPDATE_MESSAGE_LOT = 'UPDATE_MESSAGE_LOT';
export const UPDATE_STATUS_AUCTION = 'UPDATE_STATUS_AUCTION';
export const SET_MAX_AUTO_BID = 'SET_MAX_AUTO_BID';
export const ADDED_WATCHLIST = 'ADDED_WATCHLIST';
export const RESET_AUTO_BID = 'RESET_AUTO_BID';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_BIDDING_STATE = 'SET_BIDDING_STATE';
export const SET_SPENDING_LIMIT = 'SET_SPENDING_LIMIT';
export const SET_BIDDING_ERROR = 'SET_BIDDING_ERROR';
export const RESULT_BIDDING = 'RESULT_BIDDING';
export const UPDATE_CURRENT_LOT = 'UPDATE_CURRENT_LOT';
export const RESET_BID_HISTORY = 'RESET_BID_HISTORY';
export const SET_BIDDING_STARTING = 'SET_BIDDING_STARTING';
export const SET_CONNECTED_CLIENT = 'SET_CONNECTED_CLIENT';
export const SET_ERROR_CONNECTED = 'SET_ERROR_CONNECTED';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';