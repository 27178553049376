<template>
  <div class="main-nav__content">
    <SidebarPanel :activeBlock="activeBlock" :changeActiveBlock="changeActiveBlock"/>
  </div>
</template>

<script>
import SidebarPanel from "@/components/Sidebar/SidebarPanel";

export default {
  props: ['activeBlock', 'changeActiveBlock'],
  name: "ContentNavigation",
  components: {
    SidebarPanel
  }
}
</script>

<style lang="scss">
.main-nav__content {
  position: fixed;
  z-index: 500;
  top: 5.1rem;
  left: -100%;
  width: 100%;
  height: calc(100% - 9.4rem);

  @media only screen and (min-width: 1250px) {
    position: relative;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
  }
}
</style>