const getters = {
    isJoined: state => internalAuctionId => {
        return Object.prototype.hasOwnProperty.call(state.joined_auctions, internalAuctionId);
    },
    isJoinedList: state => {
        return state.joined_auctions
    },
    runList: state => auctionId => {
        return Object.prototype.hasOwnProperty.call(state.auction_item, auctionId) ? state.auction_item[auctionId] : {}
    },
    isShowPopup: state => {
        return state.showPopup
    },
    auctionItem: state => internalAuctionId => {
        return Object.prototype.hasOwnProperty.call(state.auction_list, internalAuctionId) ? state.auction_list[internalAuctionId] : null
    },
    lot: state => auctionId => {
        return Object.prototype.hasOwnProperty.call(state.lot, auctionId) ? state.lot[auctionId] : null
    },
    isAuctionStarted: state => InternalAuctionId => {
        return Object.prototype.hasOwnProperty.call(state.auction_list, InternalAuctionId) ? state.auction_list[InternalAuctionId].auctionStarted : false
    },
    status: state => InternalAuctionId => {
        return Object.prototype.hasOwnProperty.call(state.auction_list, InternalAuctionId) ? state.auction_list[InternalAuctionId].Status : false
    },
    bidHistory: state => payload => {
        if (Object.prototype.hasOwnProperty.call(state.infoCurrentLot, payload.InternalAuctionId)) {
            return state.infoCurrentLot[payload.InternalAuctionId].bidHistory;
        }

        return null;
    },
    currentHighBidLot: state => payload => {
        if (Object.prototype.hasOwnProperty.call(state.infoCurrentLot, payload.InternalAuctionId)) {
            return state.infoCurrentLot[payload.InternalAuctionId]?.currentHighBid || 0;
        }
        return 0;
    },
    currentBidLot: state => payload => {
        if (Object.prototype.hasOwnProperty.call(state.infoCurrentLot, payload.InternalAuctionId)) {
            return state.infoCurrentLot[payload.InternalAuctionId]?.currentBid || 0;
        }

        return 0;
    },
    sequenceNumber: state => payload => {
        if (Object.prototype.hasOwnProperty.call(state.auction_item, payload.InternalAuctionId)) {
            return state.auction_item[payload.InternalAuctionId]?.[payload.InternalAuctionItemId]?.SequenceNumber || null;
        }

        return null;
    },
    runListSequence: state => payload => {
        let sequence = {}

        Object.keys(state.auction_item[payload.InternalAuctionId]).forEach(key => {
            sequence[state.auction_item[payload.InternalAuctionId][key].SequenceNumber] = key
        });

        return sequence
    },
    message: state => payload => {
        if (Object.prototype.hasOwnProperty.call(state.infoCurrentLot, payload.InternalAuctionId)) {
            return state.infoCurrentLot[payload.InternalAuctionId].message;
        }

        return null;
    },
    activeLot: state => auctionId => {
        return Object.prototype.hasOwnProperty.call(state.infoCurrentLot, auctionId) ? state.infoCurrentLot[auctionId].InternalAuctionItemId : null
    },
    maxAutoBid: state => payload => {
        if (Object.prototype.hasOwnProperty.call(state.auction_item, payload.InternalAuctionId)) {
            return state.auction_item[payload.InternalAuctionId]?.[payload.InternalAuctionItemId]?.maxAutoBid || null;
        }

        return null;
    },
    currentHighBidAmount: state => payload => {
        if (Object.prototype.hasOwnProperty.call(state.auction_item, payload.InternalAuctionId)) {
            return state.auction_item[payload.InternalAuctionId]?.[payload.InternalAuctionItemId]?.currentHighBidAmount || null;
        }

        return null;
    },
    watchList: state => payload => {
        let watchListKeys = Object.keys(state.auction_item?.[payload.InternalAuctionId] || {});
        let watchlist = {};

        watchListKeys.forEach((item) => {
            if (Object.prototype.hasOwnProperty.call(state.auction_item[payload.InternalAuctionId][item], 'watchlist') && state.auction_item[payload.InternalAuctionId][item].watchlist === true) {
                watchlist = {...watchlist, [item]: state.auction_item[payload.InternalAuctionId][item]}
            }
        });

        return watchlist
    },
    userId: state => {
        return state.id
    },
    biddingState: state => payload => {
        if (Object.prototype.hasOwnProperty.call(state.auction_item, payload.InternalAuctionId)) {
            return state.auction_item[payload.InternalAuctionId]?.[payload.InternalAuctionItemId]?.biddingState || null;
        }

        return null;
    },
    spendingLimit: state => {
        return state.spendingLimit;
    },
    biddingError: state => payload => {
        if (Object.prototype.hasOwnProperty.call(state.auction_item, payload.InternalAuctionId)) {
            return state.auction_item[payload.InternalAuctionId]?.[payload.InternalAuctionItemId]?.biddingError || null;
        }

        return null;
    },
    watchListItem: state => payload => {
        if (Object.prototype.hasOwnProperty.call(state.auction_item, payload.InternalAuctionId)) {
            return state.auction_item[payload.InternalAuctionId]?.[payload.InternalAuctionItemId]?.watchlist || null;
        }

        return null;
    },
    resultBidding: state => payload => {
        if (Object.prototype.hasOwnProperty.call(state.auction_item, payload.InternalAuctionId)) {
            return state.auction_item[payload.InternalAuctionId]?.[payload.InternalAuctionItemId]?.resultBidding || null;
        }

        return null;
    },
    currentLot: state => auctionId => {
        return Object.prototype.hasOwnProperty.call(state.infoCurrentLot, auctionId) ? state.infoCurrentLot[auctionId] : null
    },
    isBiddingStarting: state => payload => {
        if (Object.prototype.hasOwnProperty.call(state.auction_item, payload.InternalAuctionId)) {
            return state.auction_item[payload.InternalAuctionId]?.[payload.InternalAuctionItemId]?.biddingStarting || null;
        }

        return null;
    },
    isConnectedClient: state => {
        return state.isConnectedClient
    },
    errorConnected: state => {
        return state.errorConnected
    },
    stateLot: state => payload => {
        if (Object.prototype.hasOwnProperty.call(state.auction_item, payload.InternalAuctionId)) {
            return state.auction_item[payload.InternalAuctionId]?.[payload.InternalAuctionItemId]?.State || 0;
        }

        return null;
    },
    getNotifications: state => {
        return state.notifications;
    }
}

export default getters;