<template>
  <div class="panel__content">
    <div class="panel__search">
      <p class="panel__note">Note: You can join a sale 60 minutes prior to sale time.</p>
      <form class="form__group form__group--search" @submit.prevent="searchAuctions">
        <input v-model="searchText" type="text" id="sidebarSearchButton"
               data-bind="value: AuctionsSearchText , event: {keyup: SearchAuctionsEnter}"
               placeholder="Branch Name, State or City" class="form__control form__control--sm">
        <button class="btn btn--xs btn--icon btn--icon-only btn--reset clearSearch"
                data-bind="visible: AuctionsSearched, click: ClearAuctionSearch" style="display: none;">
                    <span class="icon icon--sm icon-close">
                        <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path
                            d="M24 17.165L41.088.075l6.836 6.837L30.834 24l17.09 17.088-6.836 6.836L24 30.834 6.912 47.925.076 41.088 17.166 24 .075 6.912 6.912.076 24 17.166z"
                            fill-rule="evenodd"></path></svg>

                    </span>
        </button>
        <button class="btn btn--xs sidebarSearch" width="200" data-bind="click: SearchAuctions"><span
            class="icon icon--sm icon-search"><svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"
                                                   fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round"
                                                   stroke-miterlimit="1.414"><path
            d="M33.231 20.314c0-3.558-1.265-6.601-3.793-9.13-2.529-2.529-5.573-3.793-9.13-3.793-3.558 0-6.601 1.264-9.13 3.793-2.529 2.529-3.793 5.572-3.793 9.13 0 3.557 1.264 6.601 3.793 9.13 2.529 2.528 5.572 3.793 9.13 3.793 3.557 0 6.601-1.265 9.13-3.793 2.528-2.529 3.793-5.573 3.793-9.13zm14.769 24c0 1-.365 1.865-1.096 2.596-.731.731-1.596 1.096-2.596 1.096-1.039 0-1.904-.365-2.596-1.096l-9.895-9.866c-3.442 2.385-7.279 3.577-11.509 3.577-2.75 0-5.38-.533-7.89-1.601-2.509-1.067-4.673-2.509-6.49-4.326-1.817-1.818-3.26-3.981-4.327-6.491A19.956 19.956 0 0 1 0 20.314c0-2.75.534-5.38 1.601-7.89 1.067-2.509 2.51-4.673 4.327-6.49 1.817-1.817 3.981-3.26 6.49-4.327a19.964 19.964 0 0 1 7.89-1.601c2.75 0 5.38.534 7.889 1.601 2.51 1.067 4.673 2.51 6.49 4.327 1.818 1.817 3.26 3.981 4.327 6.49a19.947 19.947 0 0 1 1.601 7.89c0 4.23-1.192 8.067-3.577 11.509l9.895 9.895c.711.711 1.067 1.577 1.067 2.596z"></path></svg></span>
        </button>
      </form>
    </div>
    <loader v-if="auctionsLoading"/>
    <SidebarPanelAuction v-else/>
  </div>
</template>

<script>
import SidebarPanelAuction from "@/components/Sidebar/SidebarPanelAuction"
import {ADD_JOIN_AUCTION, SET_AUCTIONS} from "@/store/mutations.type";
import loader from "@/components/Loader/Loader";
import {mapMutations} from "vuex";

export default {
  data: function () {
    return {
      auctionsLoading: true,
      searchText: '',
    }
  },
  name: "SidebarPanelContent",
  components: {
    SidebarPanelAuction,
    loader
  },
  methods: {
    ...mapMutations({
      joinAuction: ADD_JOIN_AUCTION
    }),
    searchAuctions: function () {
      this.loadAuctions(this.searchText);
    },
    /**
     *auction loading
     *
     * @param {string} search
     */
    loadAuctions: function (search = "") {
      this.auctionsLoading = true;
      const self = this;
      this.$iaaiService.iaaiClient.auctionService.GetAuctions(search)
          .then(function (response) {
            self.$store.commit(SET_AUCTIONS, response);
            self.auctionsLoading = false
            const branch = self.$route.query.branch;
            const lane = self.$route.query.lane;

            if (self.$route.query.token) {
              self.$router.replace('/')
            }

            if (branch && lane) {
              response.forEach(item => {
                if (item.LocationId === branch && item.Lane === lane) {
                  self.$iaaiService.joinToAuction(item.InternalAuctionId, item.ExternalAuctionId, function (isNeedConfirm) {
                    if (isNeedConfirm === false) {
                      self.joinAuction(item.InternalAuctionId);
                    }
                  }, function (e) {
                    console.warn(e.message)
                  })
                }
              });
            }

            const joinedAuctions = JSON.parse(sessionStorage.getItem('joinedAuctions'));

            if(joinedAuctions) {
              Object.keys(joinedAuctions).forEach(item => {
                self.$iaaiService.joinToAuction(joinedAuctions[item].InternalAuctionId, joinedAuctions[item].ExternalAuctionId, function (isNeedConfirm) {
                  if (isNeedConfirm === false) {
                    self.joinAuction(joinedAuctions[item].InternalAuctionId);
                  }
                }, function (e) {
                  console.warn(e.message)
                })
              })
            }
          })
          .catch(function (err) {
            console.log(err)
            self.auctionsLoading = false
          })
    }
  },
  created: function () {
    this.loadAuctions()
  }
}
</script>

<style lang="scss">
.panel {

  &__content {
    overflow: auto;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    flex: 1;

    &--vehicle {

      &.close-negotiation-details {
        width: 0;
        height: 0
      }

      @media only screen and (min-width: 640px) {
        position: absolute;
      }
    }

    &--negotiation-list {
      width: 29.9rem;
      background-color: #434343;
      height: 100%;
      z-index: 2;

      &.is-details-open {
        border-right: 1px solid #666;

        @media only screen and (max-width: 576px) {
          width: 0;
          height: 0;
        }
      }
    }
  }

  &__note {
    font-size: 1.3rem;
    font-style: italic;
    color: #ccc;
  }

  &__search {
    margin: 0 1rem;

    .icon-search {
      width: 1.2rem;
      height: 1rem;
      margin-top: .2rem;
    }
  }
}

.form__control {
  overflow: hidden;
  outline: none;
  box-sizing: border-box;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  transition: border-color .15s ease-in-out, background-color .25s ease-in-out;
  text-align: left;
  color: #222;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  background-image: none;

  &--sm {
    font-size: 1.2rem;
    padding-top: .7rem;
    padding-bottom: .7rem;
    border-radius: 4px;
  }
}

.form__group {
  margin-bottom: 2rem;
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;

  & > :last-child {
    border-color: #ccc;
    border-radius: 0 4px 4px 0;

    &:hover {
      border-color: #999
    }
  }

  &--search {
    position: relative;
  }
}

.sidebarSearch {
  top: 50%;
  right: 0;

  &.btn {
    position: absolute;
    transform: translateY(-50%);
  }

  .icon {
    transition: all .2s ease-in-out;
  }

  &:hover {

    .icon {
      fill: #000;
    }
  }
}
</style>