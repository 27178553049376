<template>
  <header class="header">
    <div class="header__inner event--iaai">
      <HeaderBrand/>
      <HeaderProfile/>
    </div>
  </header>
</template>

<script>
import HeaderBrand from "@/components/Header/HeaderBrand";
import HeaderProfile from "@/components/Header/HeaderProfile";

export default {
  name: "Header",
  components: {HeaderProfile, HeaderBrand},
  comments: {
    HeaderBrand,
    HeaderProfile
  }
}
</script>

<style lang="scss">
.header {
  z-index: 993;
  background: #980105;
  box-shadow: -5px 2px 10px 0 rgba(0, 0, 0, .55);

  &__inner {
    padding: 1rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1249px) {
      padding: 1rem;
    }
  }
}
</style>