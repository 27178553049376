<template>
    <div class="logo"></div>
</template>

<script>
export default {
  name: "HeaderBrand"
}
</script>

<style lang="scss">
.logo {
  width: 5rem;
  height: 3rem;
  background-image: url("/assets/img/icons/logo.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}
</style>