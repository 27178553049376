<template>
  <div class="panel-auction__item">
    <div class="panel-auction__item-main">
      <div class="panel-auction__name">{{ auction.Name }} {{ auction.Lane ? `Lane ${auction.Lane}` : '' }}</div>
      <div class="panel-auction__address">{{ auction.City }}, {{ auction.State }}</div>
      <div class="panel-auction__item-info">
        <div class="panel-auction__date">{{ auctionDateFormatted }}</div>
        <div class="panel-auction__status" :class="statusTitle.class">{{ statusTitle.text }}</div>
      </div>
    </div>
    <div class="panel-auction__item-buttons">
      <loader v-if="isLoading"/>
      <div v-else-if="isJoined" class="panel-auction__joined">
          <span class="icon icon--sm">
            <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" lip-rule="evenodd"
                 stroke-linejoin="round" stroke-miterlimit="1.414">
              <path
                  d="M48 9.328L24.615 38.014l-5.333 6.967-6.564-5.737L0 28.999l5.744-6.967 12.307 10.655L41.026 4 48 9.328z"></path>
            </svg>
          </span>
        <span class="panel-auction__joined-label">Joined</span>
      </div>
      <div v-else-if="accessionStart()" class="panel-auction__button btn btn--sm btn--primary btn--icon btn--icon-only"
           @click="join">
              <span class="icon icon--xs">
                <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                  <path d="M28 20h20v8H28v20h-8V28H0v-8h20V0h8v20z" fill-rule="evenodd"></path>
                </svg>
              </span>
      </div>
<!--      <div v-else class="panel-auction__button btn btn&#45;&#45;sm btn&#45;&#45;primary btn&#45;&#45;icon btn&#45;&#45;icon-only"-->
<!--           @click="join">-->
<!--              <span class="icon icon&#45;&#45;xs">-->
<!--                <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path d="M28 20h20v8H28v20h-8V28H0v-8h20V0h8v20z" fill-rule="evenodd"></path>-->
<!--                </svg>-->
<!--              </span>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import {ADD_JOIN_AUCTION} from "@/store/mutations.type";
import {mapMutations} from 'vuex';
import {dateFormat} from "../../../public/assets/js/helpers";
import {mapGetters} from 'vuex';
import loader from "@/components/Loader/Loader";

export default {
  data: function () {
    return {
      isLoading: false,
      statusNumber: null,
    }
  },
  props: ['auction', 'loading', 'joined'],
  name: "SidebarPanelAuctionItem",
  components: {
    loader
  },
  methods: {
    ...mapMutations({
      joinAuction: ADD_JOIN_AUCTION
    }),
    /**
     * start of accession to the auction
     *
     * @returns {boolean}
     */
    accessionStart() {
      return new Date() > new Date(this.auction.AttendeeJoiningTime);
    },
    stopLoading() {
      this.isLoading = false;
    },
    /**
     * joins the auction
     */
    join() {
      this.isLoading = true;

      const self = this

      this.$iaaiService.joinToAuction(this.auction.InternalAuctionId, this.auction.ExternalAuctionId, function (isNeedConfirm) {
        if(isNeedConfirm === false) {
          let joinedAuctions = JSON.parse(sessionStorage.getItem('joinedAuctions')) || {};
          joinedAuctions[self.auction.InternalAuctionId] = {InternalAuctionId: self.auction.InternalAuctionId, ExternalAuctionId: self.auction.ExternalAuctionId};
          sessionStorage.setItem('joinedAuctions', JSON.stringify(joinedAuctions));
          self.joinAuction(self.auction.InternalAuctionId);
        }

        self.stopLoading()
      }, function (e) {
        console.log(e.message)
      })
    }
  },
  computed: {
    ...mapGetters(['status']),
    statusTitle() {
      let status = this.status(this.auction.InternalAuctionId);
      switch (status) {
        case 0:
          return {text: 'Not Started'};
        case 1:
          return {text: 'In Progress', class: 'panel-auction__status--progress'};
        case 2:
          return {text: 'Paused', class: 'panel-auction__status--pause'};
        case 3:
          return {text: 'In Progress', class: 'panel-auction__status--progress'};
        case 4:
          return {text: 'Completed'};
        default:
          return {text: ''};
      }
    },
    auctionDateFormatted() {
      return dateFormat(this.auction.AuctionStartTime);
    },
    isJoined() {
      return this.$store.getters.isJoined(this.auction.InternalAuctionId);
    },
  }
}
</script>

<style lang="scss">
.panel-auction {

  &__status {
    text-transform: uppercase;
    font-size: 1rem;
    min-width: 7rem;
    text-align: center;
    color: #fff;
    letter-spacing: 1px;

    &--progress {
      color: #86cf91;
    }

    &--pause {
      color: #f7e400;
    }
  }

  &__joined {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .icon {
      width: 1.1rem;
      height: 1.1rem;
      margin-top: .3rem;
      fill: #fff;
    }

    &-label {
      font-size: 1rem;
      padding-top: .5rem;
    }
  }

  &__item {
    border-bottom: 1px solid #fff;
    margin-bottom: 10px;
    padding: 10px;
    color: #ccc;
    font-size: 1.3rem;
    display: flex;

    &-main {
      width: 85%;
      padding-right: .5rem;
    }

    &-buttons {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__name {
    font-size: 1.5rem;
    color: #fff;
  }

  &__button {
    cursor: pointer;
    align-items: center;
    justify-content: center;

    &.btn {
      display: flex;
    }
  }
}
</style>