<template>
  <div class="popup">
    <div class="popup__wrap">
      <div class="popup__title">{{title}}</div>
      <component :is="popupContent" :payload="payload" :close="closePopup"></component>
    </div>
  </div>
</template>

<script>
import {POPUP_LEAVE_AUCTION, REMOVE_AUTO_BID} from "@/consts";
import { mapMutations } from 'vuex';
import {SHOW_POPUP} from "@/store/mutations.type";

export default {
  name: "Popup",
  props: ['title', 'payload'],
  components: {
    'leave-auction': () => import('./LeaveAuction'),
    'remove-auto-bid': () => import('./RemoveAutoBid')
  },
  methods: {
    ...mapMutations({showPopup: SHOW_POPUP}),
    closePopup() {
      this.showPopup(false);
    }
  },
  computed: {
    /**
     * determines which body to open popup
     *
     * @returns {string}
     */
    popupContent() {
      var component = '';

      switch (this.payload.type) {
        case POPUP_LEAVE_AUCTION:
          component = POPUP_LEAVE_AUCTION;
          break;
          case REMOVE_AUTO_BID:
            component = REMOVE_AUTO_BID;
            break;
        default:
          // this.showPopup()

      }

      return component;
    }
  }
}
</script>

<style lang="scss">
.popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 995;
  background: rgba(0, 0, 0, .5);
  display: flex;
  align-items: center;
  justify-content: center;

  &__wrap {
    max-width: 40rem;
    padding: 15px;
    background: #fff;
    border-radius: 10px;
  }

  &__title {
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
  }

  &__text {
    padding-bottom: 1rem;
  }

  &__buttons {
    display: flex;
    justify-content: center;

    .btn {
      font-size: 1.4rem;
    }

    & >div {
      cursor: pointer;

      &:first-child {
        margin-right: 15px;
      }
    }
  }
}
</style>