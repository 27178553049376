<template>
  <div id="app">
    <loader v-if="isConnectedClient"/>
    <div class="error-connected" v-else-if="errorConnected">{{errorConnected}}</div>
    <template v-else>
      <Header/>
      <Layout/>
    </template>
  </div>
</template>

<script>

import Header from './components/Header/Header';
import Layout from './components/Layout/Layout';
import {mapGetters} from "vuex";
import loader from "@/components/Loader/Loader";

export default {
  name: 'App',
  components: {
    Header,
    Layout,
    loader
  },
  computed: {
    ...mapGetters(['isConnectedClient', 'errorConnected'])
  }
}
</script>

<style lang="scss">
@import './assets/scss/main.scss';
.error-connected {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  color: #ff0000;
  font-size: 2rem;
  font-weight: 700;
}
</style>
