<template>
  <div class="profile" v-if="this.userId">
    <span class="dropdown__btn-label">{{userId}}</span>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "HeaderProfile",
  computed: {
    ...mapGetters(['userId'])
  }
}
</script>

<style lang="scss">
.profile {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  background: #630003;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  color: #fff;
  padding: .5rem;

  &:before {
    display: inline-block;
    width: 1.8rem;
    height: 1.8rem;
    margin-right: .6rem;
    content: "";
    background-image: url("/assets/img/icons/user.svg");
    background-position: 0 0;
    background-size: 1.8rem 1.8rem;
    background-repeat: no-repeat;
  }
}
</style>