<template>
  <div class="auction-container">
    <ContainerHeader :name="name" :lane="lane" :auctionId="auctionId" @auctionContainerClose="onClose"/>
    <nav-mobile :isVisible="visibleMobile" :changeVisible="changeVisibleMobile"/>
    <div class="auction-container__wrap">
      <div class="auction-container__body" :class="{'auction-container__body--view': view, 'auction-container__body--visible': !visibleMobile}">
        <div class="auction-container__body-header">
          <span class="icon icon--sm">
            <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path
                d="M31.687 3.512a1.065 1.065 0 0 1 0 1.509L21.02 15.689a1.068 1.068 0 0 1-1.508 0l-3.2-3.201a1.065 1.065 0 0 1 0-1.509L26.98.313a1.065 1.065 0 0 1 1.508 0l3.2 3.199zM26.34 29.437a1.109 1.109 0 0 1 0 1.572l-3.333 3.332a1.107 1.107 0 0 1-1.57 0l-7.779-7.777a1.112 1.112 0 0 1 0-1.572l3.334-3.332a1.106 1.106 0 0 1 1.57 0l7.778 7.777zm-7.985 4.453a1.06 1.06 0 0 1 0 1.502L6.057 47.69a1.063 1.063 0 0 1-1.502 0L.31 43.443a1.063 1.063 0 0 1 0-1.501l12.298-12.299a1.064 1.064 0 0 1 1.5 0l4.247 4.247zM39.69 15.885a1.054 1.054 0 0 1 0 1.494l-6.337 6.337a1.055 1.055 0 0 1-1.493 0l-.929-.928-3.569 3.57a1.057 1.057 0 0 1-1.495 0l-4.223-4.226a1.054 1.054 0 0 1 0-1.494l3.569-3.569-.928-.928a1.054 1.054 0 0 1 0-1.494l6.337-6.337a1.055 1.055 0 0 1 1.493 0l7.575 7.575zm7.997 3.628a1.065 1.065 0 0 1 0 1.509L37.022 31.689a1.068 1.068 0 0 1-1.509 0l-3.2-3.203a1.062 1.062 0 0 1 0-1.506L42.98 16.313a1.065 1.065 0 0 1 1.508 0l3.2 3.2z"
                fill-rule="evenodd"></path>
            </svg>
          </span>
          On the block
        </div>
        <div class="auction-container__body-main" :class="{'auction-container__body-main--view': view}">
          <auction-not-started v-if="getCurrentLot === null && getStatus === 0" :date="this.auctionStartTime"/>
          <template v-if="getCurrentLot != null">
            <info :auctionId="auctionId" :itemId="getCurrentLot.InternalAuctionItemId" :lot="getCurrentLot" :windowWidth="windowWidth"/>
            <template v-if="!view && windowWidth >= 1250">
              <vehicle :data="getCurrentLot.Attributes"/>
              <img-container :images="getCurrentLot.Images"/>
            </template>
          </template>
          <auction-completed v-if="getCurrentLot === null && getStatus === 4"/>
        </div>
      </div>
      <run-list :toggleView="toggleView" :isFull="view" :lot="getLot" :auctionId="auctionId" :isVisible="visibleMobile"/>
    </div>
  </div>
</template>

<script>
import AuctionContainerHeader from "@/components/AuctionContainer/HeaderContainer";
import RunList from "@/components/AuctionContainer/RunList/RunList";
import Vehicle from "@/components/AuctionContainer/Vehicle";
import {mapGetters, mapMutations} from 'vuex'
import ImgContainer from "@/components/AuctionContainer/ImgContainer";
import Info from "@/components/AuctionContainer/Info";
import AuctionNotStarted from "@/components/AuctionContainer/AuctionNotStarted";
import AuctionCompleted from "@/components/AuctionContainer/AuctionCompleted";
import {ADDED_WATCHLIST, SET_AUCTION_ITEM, SET_MAX_AUTO_BID} from "@/store/mutations.type";
import NavMobile from "@/components/AuctionContainer/NavMobile";
import {AUCTION_EVENT_SCROLL_TO_CURRENT_LOT} from "@/consts";

export default {
  data: function () {
    return {
      name: '',
      lane: '',
      auctionStartTime: '',
      view: false,
      visibleMobile: false,
      windowWidth: null,
    }
  },
  props: ['auctionId', 'item'],
  name: "AuctionContainer",
  components: {
    RunList,
    ContainerHeader: AuctionContainerHeader,
    vehicle: Vehicle,
    'img-container': ImgContainer,
    info: Info,
    'auction-not-started': AuctionNotStarted,
    'auction-completed': AuctionCompleted,
    'nav-mobile': NavMobile,
  },
  methods: {
    ...mapMutations({
      setAuctionItem: SET_AUCTION_ITEM,
      addedWatchlist: ADDED_WATCHLIST,
      setMaxAutoBid: SET_MAX_AUTO_BID,
    }),
    onClose(payload) {
      this.$emit('auctionContainerClose', payload)
    },
    toggleView(isView) {
      this.view = isView;
    },
    /**
     * get info about auction
     */
    getAuctionInfo() {
      const self = this;

      this.$iaaiService.iaaiClient.auctionService.GetAuction(this.auctionId)
          .then(async function (response) {
            self.name = response.Name;
            self.lane = response.Lane;
            self.auctionStartTime = response.AuctionStartTime;

            var runListResponse = await self.$iaaiService.iaaiClient.auctionService.GetRunList(self.auctionId, 'en', true);

            if (runListResponse.length !== 0) {
              self.setAuctionItem({InternalAuctionId: self.auctionId, response: runListResponse});
              self.$iaaiService.iaaiClient.engine.auctionRepo.GetAutoBids(self.auctionId, [self.getUserId])
                  .then(function (result) {
                    result.forEach(item => {
                      self.setMaxAutoBid({
                        InternalAuctionId: item.InternalAuctionId,
                        InternalAuctionItemId: item.InternalAuctionItemId,
                        MaxAutoBid: item.Amount,
                        CurrentHighBidAmount: item.CurrentHighBidAmount
                      });
                    });
                    if(self.getActiveLot) {
                      self.$iaaiService.iaaiEventBus.$emit(AUCTION_EVENT_SCROLL_TO_CURRENT_LOT, {InternalAuctionItemId: self.getActiveLot});
                    }
                  })
                  .catch(function (error) {
                    console.error('GetAutoBids', error)
                  })
              self.$iaaiService.iaaiClient.auctionService.GetWatchedItemIds(self.auctionId, [self.getUserId])
                  .then(function (result) {
                    result.forEach(item => {
                      self.addedWatchlist({
                        InternalAuctionId: self.auctionId,
                        InternalAuctionItemId: item,
                        Value: true
                      })
                    })
                  })
                  .catch(function (error) {
                    console.log('GetWatchedItemIds', error);
                  })
            }
          });
    },
    changeVisibleMobile(visible) {
      this.visibleMobile = visible;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  computed: {
    ...mapGetters(['lot', 'status', 'currentLot', 'userId', 'activeLot']),
    getLot() {
      return this.lot(this.auctionId);
    },
    getStatus() {
      return this.status(this.auctionId);
    },
    getCurrentLot() {
      return this.currentLot(this.auctionId);
    },
    getUserId() {
      return this.userId;
    },
    getActiveLot() {
      return this.activeLot(this.auctionId);
    }
  },
  created() {
    this.getAuctionInfo();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
}
</script>

<style lang="scss">
.auction-container {
  margin: 0 10px 10px auto;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100vh - 7.5rem);

  @media screen and (max-width: 1249px) {
    margin-left: 10px;
    padding: 0;
    height: calc(100vh - 11.5rem);
  }

  &:first-child {
    margin-top: 10px;
  }

  &__wrap {
    display: flex;
    flex: 1;

    @media screen and (max-width: 1249px) {
      flex-wrap: wrap;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    $body: &;

    @media screen and (min-width: 1250px) {
      margin-right: 10px;
    }

    @media screen and (max-width: 767px) {
      display: none;

      &--visible {
        display: flex;
      }
    }

    @media screen and (min-width: 768px) and (max-width: 1249px) {
      width: 50%;
    }

    &--view {
      width: 33.333%;

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      @media screen and (min-width: 768px) and (max-width: 1249px) {
        width: 50%;
      }
    }

    &:not(#{$body}--view) {
      flex: 1;
    }

    &-header {
      background-color: #cbd2db;
      padding: .7rem;
      text-align: center;
      font-weight: 700;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #222;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        width: 1.7rem;
        margin-right: .5rem;
        fill: #222;
      }

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    &-main {
      display: flex;
      flex: 1;
      background: #fff;
      $main: &;

      &:not(#{$main}--view) {
        & > div {
          width: 33.333%;

          @media screen and (max-width: 1249px) {
            width: 100%;
          }
        }
      }

      &--view {

        .info {
          width: 100%;
        }
      }
    }
  }
}

</style>