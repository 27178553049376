<template>
  <div class="auction-not-started">
    <div class="auction-not-started__title">Auction Not Started</div>
    <div class="auction-not-started__date">{{ formattedDate }} (CDT)</div>
    <div class="auction-not-started__subtitle">Auction will begin in</div>
    <div class="auction-not-started__time">{{ time }}</div>
    <div class="auction-not-started__notification">Please note that items not running today are marked "pass" on the Run
      List
    </div>
    <a href="https://www.iaai.com/AuctionRules">Auction Rules</a>
  </div>
</template>

<script>
import {dateFormat, timeStartAuction} from "../../../public/assets/js/helpers";

export default {
  props: ['date'],
  name: "AuctionNotStarted",
  data: function () {
    return {
      time: null
    }
  },
  computed: {
    /**
     * change the date format
     *
     * @returns {string}
     */
    formattedDate() {
      return dateFormat(this.date)
    }
  },
  methods: {
    /**
     * returns the time before the auction starts
     *
     * @returns {void}
     */
    getTime() {
      const self = this;
      setInterval(() => {
        self.time = timeStartAuction(self.date);
      }, 1000);
    }
  },
  mounted() {
    this.getTime();
  }
}
</script>

<style lang="scss">
.auction-not-started {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  flex: 1;

  &__title {
    font-size: 2.4rem;
    font-weight: 700;
  }

  &__subtitle {
    font-size: 1.6rem;
    font-weight: 700;
    color: #666;
  }

  &__date {
    font-weight: 700;
  }

  &__time {
    color: #86cf91;
    font-weight: 700;
    font-size: 1.6rem;
    padding-top: .5rem;
  }

  &__notification {
    margin: 1rem 0;
    max-width: 33rem;
    border-radius: 1rem;
    padding: 0.5rem 1.5rem;
    background-color: #cbd2db;
  }
}
</style>