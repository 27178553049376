const monthsShort = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'Jun.', 'Jul.', 'Aug.', 'Sept.', 'Oct.', 'Nov.', 'Dec.'];

/**
 * change in date format
 *
 * @param date
 * @return {string}
 */
export function dateFormat(date) {
    var d = new Date(date);
    return monthsShort[d.getMonth()] + ' ' + d.getDate() + ', '
        + d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
}

/**
 * time till the auction starts
 *
 * @param date
 * @returns {string}
 */
export function timeStartAuction(date) {
    const d = new Date(date) - new Date();
    const dateNow = new Date(d);
    const minutes = dateNow.getMinutes() === 0 ? '' : `${dateNow.getMinutes()}m`;
    return `${minutes} ${dateNow.getSeconds()}s`;
}