<template>
  <main>
    <popup v-if="isShowPopup" :title="title" :payload="data"/>
    <Sidebar/>
    <div class="auction-containers">
      <Container v-for="(item, name) in isJoinedList" :item="item" :auctionId="name" :key="name"
                 @auctionContainerClose="containerOnClose"/>
    </div>
  </main>
</template>

<script>
import Sidebar from "@/components/Sidebar/Sidebar";
import AuctionContainer from "@/components/AuctionContainer/Container";
import {mapGetters, mapMutations} from 'vuex'
import {REMOVE_JOIN_AUCTION, SHOW_POPUP, SET_LOT} from "@/store/mutations.type";
import Popup from "@/components/Popup/Popup";
import {AUCTION_EVENT_SHOW_POPUP} from "@/consts";

export default {
  data: function () {
    return {
      title: '',
      data: ''
    }
  },
  name: "Layout",
  components: {
    Sidebar,
    Container: AuctionContainer,
    popup: Popup
  },
  methods: {
    /**
     * show popup
     *
     * @param {object} payload
     */
    showPopup(payload) {
      this.title = payload.title;
      this.data = payload.data;
      this.popupShow(true);
    },
    containerOnClose(payload) {
      const self = this;
      this.$iaaiService.leaveAuction(payload.InternalAuctionId, function () {
        self.SET_LOT({InternalAuctionId: payload.InternalAuctionId, delete: true});
        self.removeJoinAuction(payload.InternalAuctionId);
        let joinedAuctions = JSON.parse(sessionStorage.getItem('joinedAuctions')) || {};
        delete joinedAuctions[payload.InternalAuctionId];
        sessionStorage.setItem('joinedAuctions', JSON.stringify(joinedAuctions));
      });
    },
    ...mapMutations({removeJoinAuction: REMOVE_JOIN_AUCTION, popupShow: SHOW_POPUP, SET_LOT})
  },
  computed: {
    ...mapGetters(['isJoinedList', 'isShowPopup'])
  },
  created() {
    const self = this
    /**
     * event subscription
     */
    this.$iaaiService.iaaiEventBus.$on(AUCTION_EVENT_SHOW_POPUP, function (payload) {
      self.showPopup(payload)
    })
  }

}
</script>

<style lang="scss">
.auction {

  &-containers {
    width: 100%;
    overflow-y: auto;

    @media screen and (max-width: 1249px) {
      padding-bottom: 4.5rem;
    }
  }
}
</style>