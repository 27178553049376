<template>
  <div class="main-nav__panel main-nav__panel--auctions sidebarcontent" :class="[activeBlock === 'Auctions' ? 'js-active' : '']">
    <SidebarPanelHeader title="Available Auctions" :changeActiveBlock="changeActiveBlock"/>
    <SidebarPanelContent/>
  </div>
</template>

<script>
import SidebarPanelHeader from "@/components/Sidebar/SidebarPanelHeader";
import SidebarPanelContent from "@/components/Sidebar/SidebarPanelContent";

export default {
  props: ['activeBlock', 'changeActiveBlock'],
  name: "PanelAuction",
  components: {
    SidebarPanelHeader,
    SidebarPanelContent
  }
}
</script>

<style lang="scss">
.main-nav__panel {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 100%;
  width: 80%;
  height: 100%;
  transform: translateX(-100%);
  border-right: 1px solid #666;
  background-color: #434343;
  display: flex;
  flex-direction: column;
  transition: all .4s ease-in-out;

  &.js-active {
    z-index: 500;
    transform: translateX(0);
  }

  @media screen and (min-width: 1250px) {
    .js-active {
      box-shadow: -1px 5px 15px 0 rgba(0, 0, 0, .75);
    }
  }

  @media screen and (max-width: 1250px) {
    width: 100%;
  }

  &--negotiation {
    transition: all .5s ease;
    width: 30rem;

    &.is-details-open {
      width: 68rem
    }
  }

  @media only screen and (min-width: 640px) {
    position: absolute;
    width: 30rem;
    height: 100%;

    &.js-active {
      z-index: 0
    }
  }
}
</style>