<template>
  <div class="info">
    <div style="position: relative">
      <slider :images="lot.Images"/>
      <div v-if="getResultBidding" class="media-banner" :class="getResultBiddingActiveClass">
        <div class="media-banner__inner">
          <div class="media-banner__icon">
                <span class="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
                    <defs>
                      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="a">
                        <stop stop-color="#FFF" offset="0%"></stop>
                        <stop stop-color="#DADFE5" offset="100%"></stop>
                      </linearGradient>
                      <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="b">
                        <stop stop-color="#D2E0E9" offset="0%"></stop>
                        <stop stop-color="#A6BDCE" offset="100%"></stop>
                      </linearGradient>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                      <g>
                        <circle stroke="#F8FAFC" stroke-width="4" fill="url(#a)" cx="36" cy="36" r="34"></circle>
                        <circle stroke="url(#b)" stroke-width="3" cx="36" cy="36" r="34.5"></circle>
                      </g>
                      <path
                          d="M46.666 47.224a3.185 3.185 0 013.187 3.184V52H33.92v-1.592a3.185 3.185 0 013.187-3.184h9.56z"
                          fill="#2D353F"></path>
                      <path
                          d="M33.761 33.98l-2.23-2.26-1.148 1.13c-.621.621-1.625.621-2.246 0l-1.131-1.13a1.584 1.584 0 010-2.245l9.017-9.01a1.588 1.588 0 012.247 0l1.131 1.13c.621.622.621 1.625 0 2.245L38.27 24.94l2.262 2.276a1.588 1.588 0 012.247 0c.621.62.621 1.64 0 2.26l2.246 2.245 1.131-1.13a1.607 1.607 0 012.263 0l1.115 1.13c.621.62.621 1.64 0 2.26l-9.002 8.994c-.621.621-1.64.621-2.262 0l-1.115-1.114a1.575 1.575 0 010-2.26l1.115-1.13-2.247-2.245-15.31 15.297c-.622.621-1.626.621-2.247 0a1.604 1.604 0 010-2.26L33.761 33.98z"
                          fill="#2D353F" fill-rule="nonzero"></path>
                    </g>
                  </svg>
                </span>
          </div>
          <h2 class="media-banner__heading">{{ getResultBidding.bidderId ? 'High Bidder' : 'Bidding Closed' }}</h2>
          <h3 class="media-banner__subhead">{{ getResultBidding.text }}
            {{ getResultBidding.currentBid ? '$' + getResultBidding.currentBid : '' }}</h3>
        </div>
      </div>
      <div class="label-reserve" v-if="isVisibleReserveLabel">Reserve off</div>
    </div>
    <current-vehicle :lot="lot"/>
    <div class="info__bid-area theme--dark" v-if="getStatus !== 4 && getStatus !== 2">
      <div v-if="renderTickAnimation" class="info__tick">
        <div class="info__tick-bar"></div>
      </div>
      <div class="bid-messages" v-if="getCurrentBidLot > getSpendingLimit">
        <div class="alert alert--sm">Spending Limit has been reached</div>
      </div>
      <div class="bid-messages" v-else>
        <div class="alert alert--sm" :class="textClassBidding">{{ textBidding }}</div>
      </div>
      <div class="info__high-bid" v-if="getCurrentHighBidLot.amount && getCurrentHighBidLot.display">
        CURRENT HIGH BID <span class="info__high-bid-amount">${{ getCurrentHighBidLot.amount }}</span>
        {{ getCurrentHighBidLot.display }} <span
          class="info__high-bid-amount--green">{{ getCurrentHighBidLot.bidder ? 'You' : '' }}</span>
      </div>
      <div class="info__high-bid" v-else>No bid</div>
      <template v-if="getSpendingLimit > getCurrentBidLot">
        <div class="info__buttons info__buttons--flex">
          <!--          <button class="info__button btn btn&#45;&#45;main btn&#45;&#45;md btn&#45;&#45;block btn&#45;&#45;flex" v-if="getMaxAutoBid"-->
          <!--                  id="removeAutoBid" :disabled="biddingDisabled"-->
          <!--                  @click="removeAutoBid">Remove Auto bid-->
          <!--          </button>-->
          <button class="info__button btn btn--main btn--md btn--block btn--flex" :disabled="biddingDisabled"
                  v-if="!isShowAutoBidForm"
                  id="btnAutoBid" @click="showAutoBidForm">{{ getMaxAutoBid ? 'Edit Auto Bid' : 'Auto bid' }}
          </button>
          <button class="info__button btn btn--main btn--md btn--block btn--flex"
                  :disabled="buttonDisabled || biddingDisabled"
                  @click="placeBid">Bid
            {{ getCurrentBidLot }}
          </button>
        </div>
        <div class="info__auto-bid" v-if="isShowAutoBidForm">
          <div class="info__auto-bid-label-wrapper">
            <p class="label">Autobid Amount</p>
            <p class="label label--white" v-if="getMaxAutoBid">
              Current Autobid ${{ getMaxAutoBid }}
            </p>
            <p class="label label--white">
              <span>CURRENT ASKING</span>:&nbsp;
              <span class="bid-area__amount">${{ getCurrentBidLot }}</span>
            </p>
          </div>
          <div class="form__group u-mb-0">
            <div class="form__addon">
                <span class="icon icon-dollar">
                    <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path
                        d="M27.482 41.571V48h-5.944v-6.411C15.208 40.737 11.223 37.02 11 31.512h6.779c.324 2.742 3.064 4.5 7.01 4.5 3.647 0 6.224-1.782 6.224-4.335 0-2.157-1.67-3.399-5.78-4.29l-4.366-.936c-6.108-1.29-9.1-4.5-9.1-9.657 0-5.49 3.786-9.348 9.771-10.317V0h5.944v6.468c5.813.951 9.736 4.752 9.918 9.951h-6.595c-.324-2.673-2.788-4.452-6.176-4.452-3.507 0-5.828 1.641-5.828 4.218 0 2.085 1.602 3.282 5.525 4.125l4.039.867C35.099 22.608 38 25.536 38 30.786c.003 5.946-3.938 9.876-10.518 10.785z"
                        fill-rule="evenodd"></path></svg>
                </span>
            </div>
            <label for="auto_bid_limit"></label>
            <input type="number" :min="getCurrentBidLot" step="25" name="auto_bid_limit" id="auto_bid_limit"
                   v-model.number="autoBidLimit" class="form__control form__control--xs" placeholder="My Max Bid">
          </div>
          <div v-if="error" class="error">{{ error }}</div>
          <div class="info__auto-bid-controls">
            <button class="btn btn--xs btn--cancel js-btn-cancel-bid Action" @click="showAutoBidForm">CANCEL</button>
            <loader v-if="isLoading"/>
            <button class="btn btn--xs btn--main Action" id="btn-confirm-autobid" v-else @click="placeAutoBid">
              SET AUTOBID
            </button>
          </div>
        </div>
      </template>
      <div class="info__error" v-if="getBiddingError">{{ getBiddingError }}</div>
    </div>
    <div class="info__wrap">
      <div class="info__nav">
        <div class="info__nav-item" :class="{'info__nav-item--active': activeTab === 'sale'}" data-name="sale"
             @click="changeActiveTab">Sale
        </div>
        <div class="info__nav-item" :class="{'info__nav-item--active': activeTab === 'details'}" data-name="details"
             @click="changeActiveTab">Details
        </div>
        <div class="info__nav-item" :class="{'info__nav-item--active': activeTab === 'images'}" data-name="images"
             @click="changeActiveTab">Images
        </div>
      </div>
      <info-main v-if="activeTab === 'sale'" :auctionId="auctionId" :message="lot.message" :history="lot.bidHistory"/>
      <template v-if="windowWidth < 1250">
        <vehicle v-if="activeTab === 'details'" :data="lot.Attributes"/>
        <img-container v-if="activeTab === 'images'" :images="lot.Images"/>
      </template>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {
  AUCTION_EVENT_AUTO_BID,
  AUCTION_EVENT_IAAI, AUCTION_EVENT_RESERVE,
  AUCTION_EVENT_SHOW_POPUP,
  IAAI_EVENT_BID_ACCEPTED_EVENT,
  IAAI_EVENT_BIDDING_ACTIVATE_EVENT,
  IAAI_EVENT_BIDDING_COMPLETED_EVENT,
  IAAI_EVENT_DIGITAL_AUCTION_TICK_EVENT, IAAI_EVENT_ITEM_IF_BID_EVENT, IAAI_EVENT_ITEM_SOLD_EVENT,
  REMOVE_AUTO_BID,
} from "@/consts";
import loader from "@/components/Loader/Loader";
import slider from "@/components/AuctionContainer/Slider";
import CurrentVehicle from "@/components/AuctionContainer/CurrentVehicle";
import InfoMain from "@/components/AuctionContainer/InfoMain";
import Vehicle from "@/components/AuctionContainer/Vehicle";
import ImgContainer from "@/components/AuctionContainer/ImgContainer";

export default {
  props: ['auctionId', 'itemId', 'lot', 'windowWidth'],
  data: function () {
    return {
      currentBid: 0,
      renderTickAnimation: false,
      autoBidLimit: 0,
      isShowAutoBidForm: false,
      error: '',
      isLoading: false,
      buttonDisabled: false,
      biddingDisabled: false,
      textBidding: '',
      textClassBidding: '',
      isVisibleReserveLabel: false,
      activeTab: 'sale',
      audio: null,
      audioTicking: null,
    }
  },
  name: "Info",
  components: {
    loader,
    slider,
    'current-vehicle': CurrentVehicle,
    'info-main': InfoMain,
    vehicle: Vehicle,
    'img-container': ImgContainer,
  },
  methods: {
    showAutoBidForm() {
      this.isShowAutoBidForm = !this.isShowAutoBidForm;
    },
    placeBid() {
      this.$iaaiService.iaaiClient.placeBid(this.auctionId, this.itemId, this.getCurrentBidLot, this.getUserId)
    },
    changeLoading(value) {
      this.isLoading = value;
    },
    placeAutoBid() {
      let isAutoBid = false;

      if (this.lot.AutoBid && this.lot.AutoBid.Amount && this.lot.AutoBid.Amount > 0) {
        isAutoBid = true;
      }

      if (!this.autoBidLimit || this.autoBidLimit < this.getCurrentBidLot) {
        this.error = 'Incorrect amount a bid!';
        return;
      }

      if (this.autoBidLimit > this.getSpendingLimit) {
        this.error = 'The amount of the bet exceeds the spending limit! Spending limit equals $' + this.getSpendingLimit + '.';
        return;
      }

      if (this.autoBidLimit % 25 !== 0) {
        this.error = 'The bid should be multiple 25!';
        return;
      }

      if (this.autoBidLimit < this.getCurrentHighBidAmount) {
        this.error = 'The amount cannot be less than $' + this.getCurrentHighBidAmount;
        return;
      }

      this.error = '';
      this.changeLoading(true);

      if (isAutoBid) {
        this.$iaaiService.iaaiClient.modifyAutoBid(this.auctionId, this.itemId, this.autoBidLimit, this.getUserId);
      } else {
        this.$iaaiService.iaaiClient.placeAutoBid(this.auctionId, this.itemId, this.autoBidLimit, this.getUserId);
      }
    },
    removeAutoBid() {
      this.$iaaiService.iaaiEventBus.$emit(AUCTION_EVENT_SHOW_POPUP, {
        title: 'Remove Autobid',
        data: {
          InternalAuctionId: this.auctionId,
          InternalAuctionItemId: this.itemId,
          type: REMOVE_AUTO_BID,
        }
      })
    },
    /**
     * change the active tab
     * @param e
     */
    changeActiveTab(e) {
      const nameTab = e.target.dataset.name;

      if (nameTab) {
        this.activeTab = nameTab;
      }
    },
    handleResize() {
      if (innerWidth >= 1250) {
        this.activeTab = 'sale';
      }
    },
  },
  computed: {
    ...mapGetters(['currentBidLot', 'currentHighBidLot', 'maxAutoBid', 'status', 'spendingLimit', 'biddingError', 'userId', 'resultBidding', 'currentHighBidAmount', 'getNotifications']),
    getCurrentBidLot() {
      return this.currentBidLot({InternalAuctionId: this.auctionId});
    },
    getCurrentHighBidLot() {
      return this.currentHighBidLot({InternalAuctionId: this.auctionId});
    },
    getMaxAutoBid() {
      return this.maxAutoBid({InternalAuctionId: this.auctionId, InternalAuctionItemId: this.itemId})
    },
    getCurrentHighBidAmount() {
      return this.currentHighBidAmount({InternalAuctionId: this.auctionId, InternalAuctionItemId: this.itemId})
    },
    getStatus() {
      return this.status(this.auctionId)
    },
    getSpendingLimit() {
      return this.spendingLimit;
    },
    getBiddingError() {
      return this.biddingError({InternalAuctionId: this.auctionId, InternalAuctionItemId: this.itemId})
    },
    getUserId() {
      return this.userId;
    },
    getResultBidding() {
      return this.resultBidding({InternalAuctionId: this.auctionId, InternalAuctionItemId: this.itemId})
    },
    getResultBiddingActiveClass() {
      switch (this.getResultBidding.text) {
        case 'Item sold':
          return this.getResultBidding.bidderId ? 'media-banner--sold' : '';
        case 'Item if bid':
          return this.getResultBidding.bidderId ? 'media-banner--if-bid' : '';
        default:
          return '';
      }
    }
  },
  created() {
    const self = this
    /**
     * event subscription
     */
    this.$iaaiService.iaaiEventBus.$on(AUCTION_EVENT_IAAI + self.auctionId, function (payload) {
      switch (payload.iaaiEvent) {
        case IAAI_EVENT_DIGITAL_AUCTION_TICK_EVENT:
          if(payload.Tick === 6) {
            self.renderTickAnimation = false
            self.$nextTick(() => {
              self.renderTickAnimation = true
            });
          }

          if((self.getStatus === 1 || self.getStatus === 3) && self.getNotifications === self.auctionId) {
            if(payload.Tick <= 3 ) {
              self.audioTicking.play();
            } else if(payload.Tick > 3) {
              self.audioTicking.pause();
            }

            if(payload.IsBonusTime && payload.Tick === 6) {
              self.audio = new Audio('/assets/audio/TimeExtended_male.mp3');
              self.audio.play();
            }
          } else {
            self.audioTicking.pause();
          }

          break;
        case AUCTION_EVENT_AUTO_BID:
          self.changeLoading(false);
          break;
        case IAAI_EVENT_BID_ACCEPTED_EVENT:
          self.buttonDisabled = payload.value;

          if((self.getStatus === 1 || self.getStatus === 3) && self.getNotifications === self.auctionId && payload.value !== false) {
            self.audio = new Audio('/assets/audio/coin-bid.mp3');
            self.audio.play();
          }
          break;
        case IAAI_EVENT_BIDDING_COMPLETED_EVENT:
          self.isShowAutoBidForm = false;
          self.error = '';
          self.biddingDisabled = true;
          self.changeLoading(false);
          self.textBidding = 'Bidding closed on item';
          self.textClassBidding = '';
          self.isVisibleReserveLabel = false;
          self.audioTicking.pause();
          break;
        case IAAI_EVENT_BIDDING_ACTIVATE_EVENT:
          self.biddingDisabled = false;
          self.textBidding = 'Bidding in progress';
          self.textClassBidding = 'alert--default';
          break;
        case AUCTION_EVENT_RESERVE:
          self.isVisibleReserveLabel = true;
          self.textBidding = 'Reserve off';
          self.textClassBidding = 'alert--success';

          if((self.getStatus === 1 || self.getStatus === 3) && self.getNotifications === self.auctionId) {
            self.audio = new Audio('/assets/audio/ReserveMet_male.mp3');
            self.audio.play();
          }
          break;
        case IAAI_EVENT_ITEM_SOLD_EVENT:
          if((self.getStatus === 1 || self.getStatus === 3) && self.getNotifications === self.auctionId) {
            self.audio = new Audio('/assets/audio/Sold_male.mp3');
            self.audio.play();
          }
          break;
        case IAAI_EVENT_ITEM_IF_BID_EVENT:
          if((self.getStatus === 1 || self.getStatus === 3) && self.getNotifications === self.auctionId) {
            self.audio = new Audio('/assets/audio/SalePending_male.mp3');
            self.audio.play();
          }
          break;
        default:
          console.log("Unexpected iaai event: " + payload.iaaiEvent)
          break
      }
    });
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.audioTicking = new Audio('/assets/audio/ticking-clock.mp3');
    // this.audioTicking.loop = true;
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
}
</script>

<style lang="scss">
.label-reserve {
  position: absolute;
  bottom: .8rem;
  left: .8rem;
  color: #fff;
  text-shadow: 0 2px 1px rgba(0, 0, 0, .3);
  font-size: 1.3rem;
  background: rgba(89, 182, 102, .9);
  border: .2rem solid #36AF47;
  padding: 0 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.media {

  &-banner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: rgba(55, 65, 73, .9);

    &--if-bid {
      background-color: rgba(221, 170, 104, .9);
    }

    &--sold {
      background-color: rgba(120, 182, 131, .9);
    }

    &__inner {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    &__icon {
      .icon {
        width: 7rem;
        height: 7rem;
      }
    }

    &__heading {
      font-size: 3rem;
      font-weight: 600;
      color: #fff;
      text-shadow: 0 2px 1px rgba(0, 0, 0, .3);
      text-align: center;
    }

    &__subhead {
      padding: 0 1rem;
      background-color: #fff;
      border-radius: .2rem;
      color: #222;
      font-size: 1.8rem;
    }
  }
}

.info {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-right: 1px solid #ccc;

  &__wrap {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__nav {
    display: flex;

    @media screen and (min-width: 1250px) {
      display: none;
    }

    &-item {
      flex: 1;
      border-bottom: 0.2rem solid rgba(0, 0, 0, 0.1);
      text-align: center;
      font-weight: 700;
      padding: .5rem;

      &--active {
        border-color: #b10000;
        pointer-events: none;
      }
    }
  }

  &__error {
    text-align: center;
    color: #ff0000;
    font-size: 1.6rem;
    font-weight: 700;
    padding-top: 1rem;
  }

  &__buttons {
    padding: 0 1rem;

    .btn {
      flex: 1;
    }

    &--flex {
      display: flex;
      justify-content: space-between;

      .btn {
        & + .btn {
          margin-left: 1rem;
        }
      }
    }
  }

  &__auto-bid {
    padding: 1rem 1rem 0;

    &-label {

      &-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;
      }
    }

    .icon-dollar {
      width: 1.3rem;
      height: 1.8rem;
    }

    &-controls {
      display: flex;
      justify-content: space-between;
      padding-top: 1.5rem;
    }

    .form {

      &__control {

        &:focus {
          border-color: #2ea1f8;
        }
      }

      &__addon {
        flex: 0 0 3rem;
        font-weight: 600;
        padding: 0;
        text-align: center;
        color: #222;
        border: 1px solid #ccc;
        background-color: #f2f4f7;
        background-image: linear-gradient(0deg, #f8f8f8, #fff);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px 0 0 4px;

        .icon {
          width: 1.8rem;
          height: 1.8rem;
        }
      }
    }
  }

  & > .btn--main {
    font-size: 2rem;
    padding-right: 1rem;
    padding-left: 1rem;
    height: 5.8rem;
    flex: 1;
    margin: 0 .5rem 1rem;
  }

  .bid-messages {
    padding-bottom: 1rem;
  }

  &__bid-area {
    background: linear-gradient(0deg, #2d353f, #3a444e);
    padding-bottom: 1.5rem;
    display: flex;
    flex-direction: column;

    .reserve-label,
    &-autobid-amount .autobid-label,
    .connection__label,
    .event-empty__label,
    .label {
      font-size: 1.1rem;
      font-weight: 700;
      line-height: 1;
      display: block;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #a6bdce;

      &--white {
        color: #fff;
      }
    }
  }

  &__high-bid {
    font-size: 1.3rem;
    margin-bottom: .5rem;
    text-align: center;
    color: #fff;

    &-amount {
      font-size: 1.5rem;
      font-weight: 600;

      &--green {
        color: #78b683;
      }
    }
  }

  &__tick {
    height: 12px;
    overflow: hidden;
    background-color: #44505c;
    border: 2px solid #2d3640;
    border-radius: 4px;
    z-index: 3;

    &-bar {
      animation: 6s linear 0s 1 normal forwards running kfVariation-6;
      width: 100%;
      height: 8px;
      border-radius: 2px;
    }

    @keyframes kfVariation-6 {
      0% {
        background-color: rgb(54, 175, 71);
      }
      0% {
        transform: translateX(100%);
      }
      3% {
        transform: translateX(0%);
      }
      70% {
        background-color: rgb(234, 54, 61);
      }
      30% {
        background-color: rgb(253, 207, 59);
      }
      100% {
        background-color: rgb(234, 54, 61);
        transform: translateX(100%);
      }
    };
  }

  &__button {
    color: #fff;
    background: #2bba38;
    padding: 1rem;
    margin-bottom: 1rem;
  }

  &__text {
    padding: 0 1rem 1rem;
  }

  &__title {
    font-weight: 700;
    padding-bottom: .5rem;
  }

  &__message {

    &-item {
      padding: .5rem 1.5rem;
      border-radius: 8px;
      color: #222;
      background: #dadfe5;
      margin-bottom: .5rem;
    }
  }

  &__history {

    &-item {
      border-bottom: .1rem solid #ccc;
      padding: 0 1rem;

      &:first-child {
        border-top: .1rem solid #ccc;
      }

      &:nth-child(odd) {
        background-color: #f8f8f8;
      }
    }
  }

  &__message,
  &__history {
    padding-bottom: 1rem;
  }

  &__main {
    overflow-y: auto;
    padding: 1rem;
    flex: 1;
  }
}
</style>