<template>
  <div class="img-container" :class="{'img-container--list-view':listView}">
    <div class="img-container__header">
      <div class="img-container__header-title">
        <span class="icon icon--sm icon-images">
        <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path
            d="M24.785 26.116c1.774 0 3.216-1.435 3.216-3.197 0-1.763-1.442-3.197-3.216-3.197-1.773 0-3.216 1.434-3.216 3.197 0 1.762 1.443 3.197 3.216 3.197zm8.697 12.937v-3.811l-2.925-2.784-3.958 3.56c-.695.59-1.67.58-2.352-.026l-10.11-8.822-9.952 8.097v3.786h29.297zM4.185 17.603v12.91l8.842-7.164c.696-.577 1.797-.559 2.473.04l9.955 8.808 4.157-3.54c.685-.584 1.443-.582 2.127.003l1.743 1.761V17.603H4.185zm34.006 23.542a2.093 2.093 0 0 1-2.093 2.093H2.093A2.092 2.092 0 0 1 0 41.145V15.51c0-1.156.936-2.093 2.093-2.093h34.005c1.156 0 2.093.937 2.093 2.093v25.635zm9.776-28.58l-4.974 23.958a1.57 1.57 0 1 1-3.074-.638l4.654-22.413-30.002-6.059-.741 3.34a1.569 1.569 0 1 1-3.065-.681l1.075-4.843a1.57 1.57 0 0 1 1.843-1.198l33.058 6.678a1.566 1.566 0 0 1 1.226 1.857z"
            fill-rule="evenodd"></path></svg>
      </span>
        {{ images && images.length ? images.length + ' images' : '' }}
      </div>
      <div class="img-container__header-switcher" @click="toggleListView">
        <span class="icon icon--sm icon-grid-view">
          <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path
              d="M31.343 40.09h12.293v-9.123H31.343v9.124zm13.587 4.365H30.05a3.066 3.066 0 0 1-3.07-3.055V29.657a3.067 3.067 0 0 1 3.07-3.054h14.88c1.693 0 3.07 1.37 3.07 3.054v11.744a3.066 3.066 0 0 1-3.07 3.054zM4.364 40.09h12.293v-9.124H4.364v9.124zm13.586 4.364H3.07A3.066 3.066 0 0 1 0 41.4V29.657a3.066 3.066 0 0 1 3.07-3.054h14.88c1.693 0 3.07 1.37 3.07 3.054v11.744a3.066 3.066 0 0 1-3.07 3.054zm13.393-27.968h12.293V7.363H31.343v9.124zm13.587 4.364H30.05a3.066 3.066 0 0 1-3.07-3.054V6.053A3.065 3.065 0 0 1 30.048 3h14.88C46.624 3 48 4.37 48 6.055v11.742a3.065 3.065 0 0 1-3.07 3.054zM4.364 16.487h12.293V7.363H4.364v9.124zm13.586 4.364H3.07A3.066 3.066 0 0 1 0 17.797V6.053A3.065 3.065 0 0 1 3.07 3h14.88c1.693 0 3.07 1.37 3.07 3.055v11.742a3.065 3.065 0 0 1-3.07 3.054z"
              fill-rule="evenodd"></path></svg>
        </span>
        <span class="icon icon--sm icon-video icon-list-view">
          <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path
              d="M6.798 32.547h34.404V10.82H6.798v21.727zm34.19 12.325H7.012C3.145 44.872 0 41.658 0 37.707V10.162C0 6.212 3.144 3 7.009 3h33.979C44.854 3 48 6.214 48 10.165v27.543c0 3.951-3.145 7.164-7.011 7.164z"
              fill-rule="evenodd"></path></svg>
        </span>
      </div>
    </div>
    <div class="img-container__main">
      <div v-for="item in images" :key="item.FullUri" class="img-container__item">
        <img :src="item.FullUri" :alt="item.Description" class="img-container__item-img">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['images'],
  data: function () {
    return {
      listView: false,
    }
  },
  name: "img-container",
  methods: {
    toggleListView() {
      this.listView = !this.listView;
    }
  }
}
</script>

<style lang="scss">
.img-container {
  overflow-y: auto;
  height: 100%;
  padding: 1rem;
  $img: &;

  &#{$img}--list-view {
    .icon-list-view {
      fill: #fff;
    }

    #{$img} {

      &__header-switcher {

        &:before {
          left: calc(50% - .2rem);
        }
      }

      &__main {
        flex-direction: column;
      }

      &__item {
        width: 100%;
      }
    }
  }

  &:not(#{$img}--list-view) {
    .icon-grid-view {
      fill: #fff;
    }

    #{$img} {

      &__header-switcher {

        &:before {
          left: 0;
        }
      }

      &__main {
        flex-wrap: wrap;
      }

      &__item {
        width: calc(50% - .5rem);

        &:nth-child(odd) {
          padding-right: .5rem;
        }

        &:nth-child(even) {
          padding-left: .5rem;
        }

        &-img {
          max-width: 100%;
        }
      }
    }
  }

  &__item {
    padding-bottom: 1rem;

    &:last-child {
      padding-bottom: 0;
    }

    &-img {
      max-width: 100%;
    }
  }

  &__header {
    padding-bottom: 1rem;
    font-size: 1.4rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      display: flex;
      align-items: center;

      .icon {
        margin-right: .5rem;
      }
    }

    &-switcher {
      position: relative;
      z-index: 1;
      width: 100%;
      user-select: none;
      border: 1px solid #ccc;
      border-radius: 20px;
      background: #f8f8f8;
      max-width: 9rem;
      padding: .8rem 0;
      display: flex;
      justify-content: space-around;
      cursor: pointer;
      $switcher: &;

      .icon {
        transition: all .2s ease-in-out;
      }

      &:before {
        content: '';
        position: absolute;
        transition: all .25s cubic-bezier(0, .95, .38, .98);
        border: 1px solid #0c73c1;
        border-radius: 20px;
        background-image: linear-gradient(0deg, #1991eb, #2ea1f8);
        z-index: -1;
        top: 0;
        bottom: 0;
        margin: .2rem;
        display: block;
        width: calc(50% - .2rem);
      }
    }
  }

  &__main {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>