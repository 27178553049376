
// IAAI Events
export const IAAI_EVENT_AUCTION_JOINED = 'AuctionJoinedEvent'
export const IAAI_EVENT_LEAVE_AUCTION_CONFIRMATION = 'LeaveAuctionConfirmationEvent'
export const IAAI_EVENT_AUCTION_STARTED_EVENT = 'AuctionStartedEvent'
export const IAAI_EVENT_AUCTION_ENDED_EVENT = 'AuctionEndedEvent'
export const IAAI_EVENT_AUCTION_PAUSED_EVENT = 'AuctionPausedEvent'
export const IAAI_EVENT_AUCTION_RESUMED_EVENT = 'AuctionResumedEvent'
export const IAAI_EVENT_NAVIGATED_TO_EVENT = 'NavigatedToEvent'
export const IAAI_EVENT_BIDDING_ACTIVATE_EVENT = 'BiddingActivatedEvent'
export const IAAI_EVENT_DIGITAL_AUCTION_TICK_EVENT = 'DigitalAuctionTickEvent'
export const IAAI_EVENT_COUNTER_STOPPED_TICK_EVENT = 'CounterStoppedEvent'
export const IAAI_EVENT_BIDDING_COMPLETED_EVENT = 'BiddingCompletedEvent'
export const IAAI_EVENT_DIGITAL_AUCTION_COUNTDOWN_COMPLETED_EVENT = 'DigitalAuctionCountdownCompletedEvent'
export const IAAI_EVENT_ITEM_PASS_EVENT = 'ItemPassEvent'
export const IAAI_EVENT_ITEM_SOLD_EVENT = 'ItemSoldEvent'
export const IAAI_EVENT_ITEM_NO_BID_EVENT = 'ItemNoBidEvent'
export const IAAI_EVENT_ITEM_IF_BID_EVENT = 'ItemIfBidEvent'
export const IAAI_EVENT_ITEM_RERUN_EVENT = 'ItemRerunEvent'
export const IAAI_EVENT_BID_ACCEPTED_EVENT = 'BidAcceptedEvent'
export const IAAI_EVENT_BID_REJECTED_EVENT = 'BidRejectedEvent'
export const IAAI_EVENT_ITEM_PERSISTED_EVENT = 'ItemPersistedEvent'
export const IAAI_EVENT_INCREMENT_SET_EVENT = 'IncrementSetEvent'
export const IAAI_EVENT_ASKING_AMOUNT_SET_EVENT = 'AskingAmountSetEvent'
export const IAAI_EVENT_AUTO_BID_PLACE_EVENT = 'AutoBidPlacedEvent'
export const IAAI_EVENT_AUTO_BID_MODIFY_EVENT = 'AutoBidModifiedEvent'
export const IAAI_EVENT_AUTO_BID_REMOVE_EVENT = 'AutoBidRemovedEvent'
export const IAAI_EVENT_WATCHLIST_ITEM_ADDED_EVENT = 'WatchListItemAddedEvent'
export const IAAI_EVENT_WATCHLIST_ITEM_REMOVED_EVENT = 'WatchListItemRemovedEvent'
export const IAAI_EVENT_SELECTED_USERS_EVENT = 'SelectedUsersEvent'
export const IAAI_EVENT_TRANSIENT_SPENDING_LIMIT_EVENT = 'TransientSpendingLimitEvent'
export const IAAI_EVENT_RESERVE_PRICE_REMOVED_EVENT = 'ReservePriceRemovedEvent'
export const IAAI_EVENT_RESERVE_PRICE_MET_EVENT = 'ReservePriceMetEvent'
export const IAAI_EVENT_LEAVE_AUCTION_EVENT = 'LeaveAuctionEvent'


// Auction Event Bus Events
export const AUCTION_EVENT_SHOW_POPUP = 'AUCTION_EVENT_SHOW_POPUP'
export const AUCTION_EVENT_IAAI = 'AUCTION_EVENT_IAAI_'
export const AUCTION_EVENT_SCROLL_TO_CURRENT_LOT = 'AUCTION_EVENT_SCROLL_TO_CURRENT_LOT'
export const AUCTION_EVENT_AUTO_BID = 'AUCTION_EVENT_AUTO_BID'
export const AUCTION_EVENT_RESERVE = 'AUCTION_EVENT_RESERVE'


//Popup type
export const POPUP_LEAVE_AUCTION = 'leave-auction';
export const REMOVE_AUTO_BID = 'remove-auto-bid';
