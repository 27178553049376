<template>
  <div class="run-list__item" :class="{'run-list__item--active': isActive}" @click="openAuctionLot">
    <div class="run-list__item-body">
      <div class="run-list__item-wrap">
        <div class="run-list__item-number">{{ auctionItem.SequenceNumber }}</div>
        <div class="run-list__item-favorite" @click.stop="WatchListToggleHandler">
          <img :src="'/assets/img/icons/favorite' + (auctionItem.watchlist ? '-active' : '') + '.svg'" alt="">
        </div>
      </div>
      <div class="run-list__item-img">
        <img :src="auctionItem.ThumbnailImageUri" class="run-list__item-image" alt="">
      </div>
      <div class="run-list__item-main">
        <div class="run-list__item-stock">Stock #: <span class="run-list__item-stock--black">{{
            auctionItem.StockNumber
          }}</span></div>
        <div class="run-list__item-badge">
          <div class="badge badge--xs badge--ol badge--success"
               v-if="auctionItem.Drives && auctionItem.Drives === 'yes'">Drives
          </div>
          <!--          <div class="badge badge&#45;&#45;xs badge&#45;&#45;ol badge&#45;&#45;success" v-if="auctionItem.Starts">Starts</div>-->
          <div class="badge badge--xs badge--ol badge--info" v-if="auctionItem.Public === 'yes'">Public</div>
        </div>
        <div class="run-list__item-info">{{ auctionItem.YearMakeModel }}</div>
        <div class="run-list__item-sale-doc-all">{{ auctionItem.SaleDocAll }}</div>
        <div class="run-list__item-location" v-if="auctionItem.Location">{{ auctionItem.Location }}</div>
        <div v-if="auctionItem.maxAutoBid">My AutoBid: ${{ auctionItem.maxAutoBid }}</div>
        <div v-if="stateLot"><span :class="stateLot.cls">{{ stateLot.text }}</span> ${{ stateLot.bid }}
          {{ stateLot.bidderId ? '(You)' : '' }}
        </div>
        <div v-if="getResultBidding"><span :class="getResultBidding.cls">{{ getResultBidding.text }}</span> ${{ getResultBidding.bid }}
          {{ getResultBidding.bidderId ? '(You)' : '' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import {SET_LOT} from "@/store/mutations.type";

export default {
  props: ['auctionItem', 'toggleView', 'index'],
  name: "Item",
  methods: {
    ...mapMutations({setLot: SET_LOT}),
    /**
     * receives information about the lot and writes it into the store
     *
     * @returns {void}
     */
    async openAuctionLot() {
      const response = await this.$iaaiService.iaaiClient.auctionService.GetAuctionItem(this.auctionItem.InternalAuctionId, this.auctionItem.InternalAuctionItemId, 'en', true);
      this.setLot({...response, index: this.index});
      this.toggleView(true);
    },
    /**
     * Watchlist toggle handler
     *
     * @returns {void}
     */
    WatchListToggleHandler() {
      if (this.auctionItem.watchlist) {
        this.$iaaiService.iaaiClient.removeFromWatchList(this.auctionItem.InternalAuctionId, this.auctionItem.InternalAuctionItemId, this.getUserId);
      } else {
        this.$iaaiService.iaaiClient.addToWatchList(this.auctionItem.InternalAuctionId, this.auctionItem.InternalAuctionItemId, this.getUserId);
      }
    }
  },
  computed: {
    ...mapGetters(['activeLot', 'userId', 'resultBidding']),
    /**
     * determines whether the current lot is active
     *
     * @returns {boolean}
     */
    isActive() {
      const activeLot = this.activeLot(this.auctionItem.InternalAuctionId);
      return activeLot === this.auctionItem.InternalAuctionItemId;
    },
    /**
     * returns the bidder id
     *
     * @returns {string || null}
     */
    getUserId() {
      return this.userId;
    },
    stateLot() {
      if (this.auctionItem.WinningBidAmount) {
        switch (this.auctionItem.State) {
          case (4):
            return {
              text: 'Sold',
              cls: 'run-list__item-sold',
              bid: this.auctionItem.WinningBidAmount,
              bidderId: this.auctionItem.WinningBidderId === this.getUserId,
            }
          case (8):
            return {
              text: 'If Bid',
              cls: 'run-list__item-if-bid',
              bid: this.auctionItem.WinningBidAmount,
              bidderId: this.auctionItem.WinningBidderId === this.getUserId,
            }
          default:
            return null;
        }
      }

      return null;
    },
    getResultBidding() {
      const resultBidding = this.resultBidding({
        InternalAuctionId: this.auctionItem.InternalAuctionId,
        InternalAuctionItemId: this.auctionItem.InternalAuctionItemId,
      });

      if(resultBidding && resultBidding.text) {
        switch (resultBidding.text) {
          case 'Item if bid':
            return {
              text: 'If Bid',
              cls: 'run-list__item-if-bid',
              bid: resultBidding.currentBid,
              bidderId: resultBidding.bidderId === this.getUserId,
            }
          case 'Item sold':
            return {
              text: 'Sold',
              cls: 'run-list__item-sold',
              bid: resultBidding.currentBid,
              bidderId: resultBidding.bidderId === this.getUserId,
            }
          default:
            return null;
        }
      }

      return null;
    }
  }
}
</script>

<style lang="scss">
.run-list {

  &__item {
    cursor: pointer;
    padding: .5rem;

    &--active {
      padding-top: 0;
      box-shadow: 0 0 10px 2px rgba(20, 41, 57, .25);
      border-top: 1px solid #999;
      border-bottom: 1px solid #999;

      &:before {
        font-size: 1.1rem;
        font-weight: 700;
        display: block;
        height: 2.4rem;
        margin: 0 -.5rem .5rem;
        padding-top: .4rem;
        content: "ON THE BLOCK";
        text-align: center;
        letter-spacing: 1px;
        color: #222;
        background: #cbd2db;
      }
    }

    &-location {
      background: #fdead2;
      border: 1px solid #fac177;
      color: #574a30;
      padding: .1rem .5rem;
      border-radius: .4rem;
      margin: .3rem 0;
    }

    &-badge {
      display: flex;
    }

    &-sale-doc-all {
      font-size: 1.3rem;
    }

    &-body {
      display: flex;
    }

    &-stock {
      font-size: 1.2rem;
      font-weight: 600;
      color: #666;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &--black {
        color: #222;
      }
    }

    &-info {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #222;
      font-weight: 600;
    }

    &-number {
      min-width: 2.5rem;
      color: #fff;
      border-radius: 2rem;
      background: #008c99;
      font-size: 1.5rem;
      font-weight: 700;
      text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
      padding: 0 .5rem;
      line-height: 1.7;
      margin-bottom: 1rem;
      text-align: center;
    }

    &-favorite {
      height: 2.5rem;
      width: 2.5rem;
    }

    &-img {
      padding-right: 1rem;
      width: 39%;
    }

    &-wrap {
      width: 5rem;
      padding-right: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-main {
      flex: 1;
    }

    &-image {
      width: 100%;
    }

    &-sold {
      color: #36AF47;
    }

    &-if-bid {
      color: #F7981C;
    }
  }
}
</style>