<template>
<div class="auction-completed">
  <div class="auction-completed__title">Auction Completed</div>
</div>
</template>

<script>
export default {
name: "AuctionCompleted"
}
</script>

<style lang="scss">
.auction-completed {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  flex: 1;

  &__title {
    font-size: 2.4rem;
    font-weight: 700;
  }

  &__subtitle {
    font-size: 1.6rem;
    font-weight: 700;
    color: #666;
  }
}
</style>