import {
    AUCTION_EVENT_AUTO_BID,
    AUCTION_EVENT_IAAI, AUCTION_EVENT_RESERVE,
    AUCTION_EVENT_SCROLL_TO_CURRENT_LOT,
    AUCTION_EVENT_SHOW_POPUP,
    IAAI_EVENT_ASKING_AMOUNT_SET_EVENT,
    IAAI_EVENT_AUCTION_ENDED_EVENT,
    IAAI_EVENT_AUCTION_JOINED,
    IAAI_EVENT_AUCTION_PAUSED_EVENT,
    IAAI_EVENT_AUCTION_RESUMED_EVENT,
    IAAI_EVENT_AUCTION_STARTED_EVENT,
    IAAI_EVENT_AUTO_BID_MODIFY_EVENT,
    IAAI_EVENT_AUTO_BID_PLACE_EVENT,
    IAAI_EVENT_AUTO_BID_REMOVE_EVENT,
    IAAI_EVENT_BID_ACCEPTED_EVENT, IAAI_EVENT_BID_REJECTED_EVENT,
    IAAI_EVENT_BIDDING_ACTIVATE_EVENT,
    IAAI_EVENT_BIDDING_COMPLETED_EVENT,
    IAAI_EVENT_COUNTER_STOPPED_TICK_EVENT,
    IAAI_EVENT_DIGITAL_AUCTION_COUNTDOWN_COMPLETED_EVENT,
    IAAI_EVENT_DIGITAL_AUCTION_TICK_EVENT,
    IAAI_EVENT_INCREMENT_SET_EVENT,
    IAAI_EVENT_ITEM_IF_BID_EVENT,
    IAAI_EVENT_ITEM_NO_BID_EVENT,
    IAAI_EVENT_ITEM_PASS_EVENT,
    IAAI_EVENT_ITEM_PERSISTED_EVENT,
    IAAI_EVENT_ITEM_RERUN_EVENT,
    IAAI_EVENT_ITEM_SOLD_EVENT,
    IAAI_EVENT_LEAVE_AUCTION_CONFIRMATION, IAAI_EVENT_LEAVE_AUCTION_EVENT,
    IAAI_EVENT_NAVIGATED_TO_EVENT, IAAI_EVENT_RESERVE_PRICE_MET_EVENT, IAAI_EVENT_RESERVE_PRICE_REMOVED_EVENT,
    IAAI_EVENT_SELECTED_USERS_EVENT, IAAI_EVENT_TRANSIENT_SPENDING_LIMIT_EVENT,
    IAAI_EVENT_WATCHLIST_ITEM_ADDED_EVENT,
    IAAI_EVENT_WATCHLIST_ITEM_REMOVED_EVENT,
    POPUP_LEAVE_AUCTION
} from "@/consts";
import store from "@/store";
import {
    ADDED_WATCHLIST,
    AUCTION_START, REMOVE_JOIN_AUCTION,
    RESET_AUTO_BID,
    RESET_BID_HISTORY,
    RESULT_BIDDING,
    SET_BIDDING_ERROR,
    SET_BIDDING_STARTING,
    SET_BIDDING_STATE, SET_CONNECTED_CLIENT, SET_ERROR_CONNECTED, SET_LOT,
    SET_MAX_AUTO_BID,
    SET_SPENDING_LIMIT,
    SET_USER_ID,
    UPDATE_BID_HISTORY,
    UPDATE_CURRENT_BID_LOT,
    UPDATE_CURRENT_HIGH_BID_LOT,
    UPDATE_CURRENT_LOT,
    UPDATE_MESSAGE_LOT,
    UPDATE_STATUS_AUCTION
} from "@/store/mutations.type";
import Vue from 'vue'


function IaaiEventBus() {
    this.iaaiEventBus = new Vue();
    this.iaaiService = null;
}

IaaiEventBus.prototype.init = function (iaaiClient, iaaiService) {
    const self = this
    this.iaaiService = iaaiService;
    this.iaaiClient = iaaiClient

    iaaiClient.on("All", function (e) {
        console.log('All', e)
    });

    iaaiClient.on(IAAI_EVENT_AUCTION_JOINED, function (e) {
        self.iaaiEventBus.$emit(AUCTION_EVENT_IAAI + e.InternalAuctionId, {
            iaaiEvent: IAAI_EVENT_AUCTION_JOINED
        });
    });

    iaaiClient.on(IAAI_EVENT_LEAVE_AUCTION_CONFIRMATION, function (e) {
        self.iaaiEventBus.$emit(AUCTION_EVENT_IAAI + e.InternalAuctionId, {
            iaaiEvent: IAAI_EVENT_LEAVE_AUCTION_CONFIRMATION
        });
        var auction = store.getters.auctionItem(e.InternalAuctionId);

        self.iaaiEventBus.$emit(AUCTION_EVENT_SHOW_POPUP, {
            title: 'Warning',
            data: {
                InternalAuctionId: e.InternalAuctionId,
                Name: auction.Name,
                Lane: auction.Lane,
                type: POPUP_LEAVE_AUCTION,
                ExternalAuctionId: auction.ExternalAuctionId,
            }
        })
    });

    iaaiClient.on(IAAI_EVENT_AUCTION_STARTED_EVENT, function (e) {
        store.commit(AUCTION_START, e.InternalAuctionId);
        store.commit(UPDATE_STATUS_AUCTION, {InternalAuctionId: e.InternalAuctionId, Status: e.AuctionStatus})
    });


    iaaiClient.on(IAAI_EVENT_NAVIGATED_TO_EVENT, async function (e) {
        var isStarted = store.getters['isAuctionStarted'](e.InternalAuctionId);

        if (isStarted) {
            store.commit(SET_BIDDING_STATE, {
                InternalAuctionId: e.InternalAuctionId,
                InternalAuctionItemId: e.InternalAuctionItemId,
                BiddingState: true
            });
            store.commit(UPDATE_CURRENT_HIGH_BID_LOT, {
                InternalAuctionId: e.InternalAuctionId,
                Amount: 0,
                text: 'Navigated to event'
            });
            store.commit(RESET_BID_HISTORY, {InternalAuctionId: e.InternalAuctionId,});
            self.iaaiEventBus.$emit(AUCTION_EVENT_SCROLL_TO_CURRENT_LOT, {InternalAuctionItemId: e.InternalAuctionItemId});
            const response = await iaaiClient.auctionService.GetAuctionItem(e.InternalAuctionId, e.InternalAuctionItemId, 'en', true);
            store.commit(UPDATE_CURRENT_LOT, {InternalAuctionId: e.InternalAuctionId, lot: response});
        }
    });

    iaaiClient.on(IAAI_EVENT_BIDDING_ACTIVATE_EVENT, function (e) {
        self.iaaiEventBus.$emit(AUCTION_EVENT_IAAI + e.InternalAuctionId, {
            iaaiEvent: IAAI_EVENT_BIDDING_ACTIVATE_EVENT
        });
        store.commit(SET_BIDDING_STARTING, {
            InternalAuctionId: e.InternalAuctionId,
            InternalAuctionItemId: e.InternalAuctionItemId,
            BiddingStarting: true
        });
        const sequenceNumber = store.getters['sequenceNumber']({
            InternalAuctionId: e.InternalAuctionId,
            InternalAuctionItemId: e.InternalAuctionItemId
        });
        if (sequenceNumber) {
            store.commit(UPDATE_BID_HISTORY, {
                InternalAuctionId: e.InternalAuctionId,
                bidHistory: {Text: `Run #${sequenceNumber}: Bidding Open`}
            });
        }
    });

    iaaiClient.on(IAAI_EVENT_DIGITAL_AUCTION_TICK_EVENT, function (e) {
        self.iaaiEventBus.$emit(AUCTION_EVENT_IAAI + e.InternalAuctionId, {
            iaaiEvent: IAAI_EVENT_DIGITAL_AUCTION_TICK_EVENT,
            IsBonusTime: e.IsBonusTime,
            Tick: e.Tick,
        });
    });

    iaaiClient.on(IAAI_EVENT_COUNTER_STOPPED_TICK_EVENT, function () {
        console.log('finish', IAAI_EVENT_COUNTER_STOPPED_TICK_EVENT);
    });

    iaaiClient.on(IAAI_EVENT_BIDDING_COMPLETED_EVENT, function (e) {
        self.iaaiEventBus.$emit(AUCTION_EVENT_IAAI + e.InternalAuctionId, {
            iaaiEvent: IAAI_EVENT_BID_ACCEPTED_EVENT,
            value: false
        });
        self.iaaiEventBus.$emit(AUCTION_EVENT_IAAI + e.InternalAuctionId, {
            iaaiEvent: IAAI_EVENT_BIDDING_COMPLETED_EVENT
        });
        store.commit(SET_BIDDING_STATE, {
            InternalAuctionId: e.InternalAuctionId,
            InternalAuctionItemId: e.InternalAuctionItemId,
            BiddingState: false
        });
        self.iaaiEventBus.$emit(AUCTION_EVENT_IAAI + e.InternalAuctionId, {
            iaaiEvent: IAAI_EVENT_AUTO_BID_REMOVE_EVENT
        });
        // store.commit(RESULT_BIDDING, {
        //     InternalAuctionId: e.InternalAuctionId,
        //     InternalAuctionItemId: e.InternalAuctionItemId,
        //     ResultBidding: null
        // })
    });

    iaaiClient.on(IAAI_EVENT_DIGITAL_AUCTION_COUNTDOWN_COMPLETED_EVENT, function () {
        console.log('finish', IAAI_EVENT_DIGITAL_AUCTION_COUNTDOWN_COMPLETED_EVENT);
    });

    iaaiClient.on(IAAI_EVENT_ITEM_PASS_EVENT, function (e) {
        const currentBid = store.getters['currentBidLot']({InternalAuctionId: e.InternalAuctionId});
        store.commit(UPDATE_MESSAGE_LOT, {
            InternalAuctionId: e.InternalAuctionId,
            message: {currentBid, sequenceNumber: e.SequenceNumber, text: 'Item Pass'}
        });
        store.commit(RESULT_BIDDING, {
            InternalAuctionId: e.InternalAuctionId,
            InternalAuctionItemId: e.InternalAuctionItemId,
            ResultBidding: {
                text: 'Item Pass',
                currentBid: null
            }
        })
    });

    iaaiClient.on(IAAI_EVENT_ITEM_SOLD_EVENT, function (e) {
        store.commit(UPDATE_MESSAGE_LOT, {
            InternalAuctionId: e.InternalAuctionId,
            message: {currentBid: e.Amount, sequenceNumber: e.SequenceNumber, text: 'Item sold'}
        });
        store.commit(RESULT_BIDDING, {
            InternalAuctionId: e.InternalAuctionId,
            InternalAuctionItemId: e.InternalAuctionItemId,
            ResultBidding: {
                text: 'Item sold',
                currentBid: e.Amount,
                bidderId: e.BidderId === store.getters['userId'] ? e.BidderId : null
            }
        });
        self.iaaiEventBus.$emit(AUCTION_EVENT_IAAI + e.InternalAuctionId, {
            iaaiEvent: IAAI_EVENT_ITEM_SOLD_EVENT
        });
    });

    iaaiClient.on(IAAI_EVENT_ITEM_NO_BID_EVENT, function (e) {
        const currentBid = store.getters['currentBidLot']({InternalAuctionId: e.InternalAuctionId});
        store.commit(UPDATE_MESSAGE_LOT, {
            InternalAuctionId: e.InternalAuctionId,
            message: {currentBid, sequenceNumber: e.SequenceNumber, text: 'Item No Bid'}
        });
        store.commit(RESULT_BIDDING, {
            InternalAuctionId: e.InternalAuctionId,
            InternalAuctionItemId: e.InternalAuctionItemId,
            ResultBidding: {
                text: 'Item No Bid',
                currentBid: null
            }
        });
    });

    iaaiClient.on(IAAI_EVENT_ITEM_IF_BID_EVENT, function (e) {
        store.commit(UPDATE_MESSAGE_LOT, {
            InternalAuctionId: e.InternalAuctionId,
            message: {currentBid: e.Amount, sequenceNumber: e.SequenceNumber, text: 'Item if bid'}
        });
        store.commit(RESULT_BIDDING, {
            InternalAuctionId: e.InternalAuctionId,
            InternalAuctionItemId: e.InternalAuctionItemId,
            ResultBidding: {
                text: 'Item if bid',
                currentBid: e.Amount,
                bidderId: e.BidderId === store.getters['userId'] ? e.BidderId : null
            }
        });
        self.iaaiEventBus.$emit(AUCTION_EVENT_IAAI + e.InternalAuctionId, {
            iaaiEvent: IAAI_EVENT_ITEM_IF_BID_EVENT
        });
    });

    iaaiClient.on(IAAI_EVENT_ITEM_RERUN_EVENT, function (e) {
        const currentBid = store.getters['currentBidLot']({InternalAuctionId: e.InternalAuctionId});
        store.commit(UPDATE_MESSAGE_LOT, {
            InternalAuctionId: e.InternalAuctionId,
            message: {currentBid, sequenceNumber: e.SequenceNumber, text: 'Item Rerun'}
        });
        store.commit(RESULT_BIDDING, {
            InternalAuctionId: e.InternalAuctionId,
            InternalAuctionItemId: e.InternalAuctionItemId,
            ResultBidding: {
                text: 'Item Rerun',
                currentBid: null
            }
        })
    });

    iaaiClient.on(IAAI_EVENT_AUCTION_ENDED_EVENT, function (e) {
        store.commit(UPDATE_STATUS_AUCTION, {InternalAuctionId: e.InternalAuctionId, Status: e.AuctionStatus})
        store.commit(UPDATE_CURRENT_LOT, {InternalAuctionId: e.InternalAuctionId, delete: true});
    });

    iaaiClient.on(IAAI_EVENT_AUCTION_PAUSED_EVENT, function (e) {
        store.commit(UPDATE_STATUS_AUCTION, {InternalAuctionId: e.InternalAuctionId, Status: e.AuctionStatus})
    });

    iaaiClient.on(IAAI_EVENT_AUCTION_RESUMED_EVENT, function (e) {
        store.commit(UPDATE_STATUS_AUCTION, {InternalAuctionId: e.InternalAuctionId, Status: e.AuctionStatus})
    });

    iaaiClient.on(IAAI_EVENT_ITEM_PERSISTED_EVENT, function (e) {
        console.log('Item Persisted', e);
    });

    iaaiClient.on(IAAI_EVENT_INCREMENT_SET_EVENT, function (e) {
        console.log(e);
    });

    iaaiClient.on(IAAI_EVENT_ASKING_AMOUNT_SET_EVENT, function (e) {
        store.commit(UPDATE_CURRENT_BID_LOT, {
            InternalAuctionId: e.InternalAuctionId,
            Amount: e.Amount
        })
    });

    iaaiClient.on(IAAI_EVENT_BID_ACCEPTED_EVENT, function (e) {
        store.commit(UPDATE_BID_HISTORY, {
            InternalAuctionId: e.InternalAuctionId,
            bidHistory: {Amount: e.Amount, Display: e.Display, Text: `Bid $${e.Amount}, ${e.Display}`}
        });
        store.commit(UPDATE_CURRENT_HIGH_BID_LOT, {
            InternalAuctionId: e.InternalAuctionId,
            Amount: e.Amount, Display: e.Display, Bidder: e.BidderId === store.getters['userId']
        });
        store.commit(SET_BIDDING_ERROR, {
            InternalAuctionId: e.InternalAuctionId,
            InternalAuctionItemId: e.InternalAuctionItemId,
            BiddingError: ''
        });

        const id = store.getters['userId'];

        self.iaaiEventBus.$emit(AUCTION_EVENT_IAAI + e.InternalAuctionId, {
            iaaiEvent: IAAI_EVENT_BID_ACCEPTED_EVENT,
            value: e.BidderId === id
        });
    });

    iaaiClient.on(IAAI_EVENT_BID_REJECTED_EVENT, function (e) {
        let text = '';
        switch (e.Reason) {
            case 3:
                text = 'Wrong (less than current) bid amount placed'
                break;
            case 9:
                text = 'CountDown is Complete';
                break;
            case 10:
                text = 'Spending Limit has been reached';
                break;
            default:
                text = 'Sorry, there was an error. Try again.';
                break;
        }

        store.commit(SET_BIDDING_ERROR, {
            InternalAuctionId: e.InternalAuctionId,
            InternalAuctionItemId: e.InternalAuctionItemId,
            BiddingError: text
        });
    });

    iaaiClient.on(IAAI_EVENT_AUTO_BID_PLACE_EVENT, function (e) {
        store.commit(SET_MAX_AUTO_BID, {
            InternalAuctionId: e.InternalAuctionId,
            InternalAuctionItemId: e.InternalAuctionItemId,
            MaxAutoBid: e.Amount
        });
        self.iaaiEventBus.$emit(AUCTION_EVENT_IAAI + e.InternalAuctionId, {
            iaaiEvent: AUCTION_EVENT_AUTO_BID
        });
    });

    iaaiClient.on(IAAI_EVENT_AUTO_BID_MODIFY_EVENT, function (e) {
        store.commit(SET_MAX_AUTO_BID, {
            InternalAuctionId: e.InternalAuctionId,
            InternalAuctionItemId: e.InternalAuctionItemId,
            MaxAutoBid: e.Amount
        });
        self.iaaiEventBus.$emit(AUCTION_EVENT_IAAI + e.InternalAuctionId, {
            iaaiEvent: AUCTION_EVENT_AUTO_BID
        });
    });

    iaaiClient.on(IAAI_EVENT_AUTO_BID_REMOVE_EVENT, function (e) {
        store.commit(SET_MAX_AUTO_BID, {
            InternalAuctionId: e.InternalAuctionId,
            InternalAuctionItemId: e.InternalAuctionItemId,
            MaxAutoBid: null
        });
        store.commit(RESET_AUTO_BID, {
            InternalAuctionId: e.InternalAuctionId,
        });
        self.iaaiEventBus.$emit(AUCTION_EVENT_IAAI + e.InternalAuctionId, {
            iaaiEvent: IAAI_EVENT_AUTO_BID_REMOVE_EVENT
        });
    });

    iaaiClient.on(IAAI_EVENT_WATCHLIST_ITEM_ADDED_EVENT, function (e) {
        store.commit(ADDED_WATCHLIST, {
            InternalAuctionId: e.InternalAuctionId,
            InternalAuctionItemId: e.InternalAuctionItemId,
            Value: true
        })
    });

    iaaiClient.on(IAAI_EVENT_WATCHLIST_ITEM_REMOVED_EVENT, function (e) {

        store.commit(ADDED_WATCHLIST, {
            InternalAuctionId: e.InternalAuctionId,
            InternalAuctionItemId: e.InternalAuctionItemId,
            Value: false
        })
    });

    iaaiClient.on(IAAI_EVENT_TRANSIENT_SPENDING_LIMIT_EVENT, function (e) {
        const limit = e.Limit - e.AmountSpent;
        store.commit(SET_SPENDING_LIMIT, limit);
    });

    iaaiClient.on(IAAI_EVENT_SELECTED_USERS_EVENT, function (e) {
        store.commit(SET_USER_ID, e.LiveUserRoles[0].Identity.Id)
    });

    iaaiClient.on(IAAI_EVENT_RESERVE_PRICE_REMOVED_EVENT, function (e) {
        store.commit(UPDATE_BID_HISTORY, {
            InternalAuctionId: e.InternalAuctionId,
            bidHistory: {Text: 'RESERVE OFF'}
        });
        self.iaaiEventBus.$emit(AUCTION_EVENT_IAAI + e.InternalAuctionId, {
            iaaiEvent: AUCTION_EVENT_RESERVE
        });
    });

    iaaiClient.on(IAAI_EVENT_RESERVE_PRICE_MET_EVENT, function (e) {
        store.commit(UPDATE_BID_HISTORY, {
            InternalAuctionId: e.InternalAuctionId,
            bidHistory: {Text: 'RESERVE OFF'}
        });
        self.iaaiEventBus.$emit(AUCTION_EVENT_IAAI + e.InternalAuctionId, {
            iaaiEvent: AUCTION_EVENT_RESERVE
        });
    });

    iaaiClient.on(IAAI_EVENT_LEAVE_AUCTION_EVENT, function (e) {
        self.iaaiService.leaveAuction(e.InternalAuctionId, function () {
            store.commit(SET_LOT, {InternalAuctionId: e.InternalAuctionId, delete: true});
            store.commit(REMOVE_JOIN_AUCTION, e.InternalAuctionId)
            let joinedAuctions = JSON.parse(sessionStorage.getItem('joinedAuctions')) || {};
            delete joinedAuctions[e.InternalAuctionId];
            sessionStorage.setItem('joinedAuctions', JSON.stringify(joinedAuctions));
        });
    });

    iaaiClient.start()
        .then(function () {
            console.log('client start success')
            store.commit(SET_CONNECTED_CLIENT, false);
        })
        .catch(function (error) {
            console.error("failed", error)
            store.commit(SET_CONNECTED_CLIENT, false);
            store.commit(SET_ERROR_CONNECTED, 'An error has occurred. Try again.');
        });

    return self.iaaiEventBus
}


export default IaaiEventBus;