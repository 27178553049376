<template>
  <div class="vehicle">
    <template v-if="data">
      <div class="vehicle__item" v-if="data.Location && data.Location.Value">
        <div class="vehicle__title">Vehicle Location</div>
        <div class="vehicle__location">{{data.Location.Value}}</div>
      </div>
      <div class="vehicle__item">
        <div class="vehicle__title">Highlights</div>
        <div class="vehicle__data-list">
          <div class="vehicle__data-list-item" v-if="data.ConsignorName && data.ConsignorName.DisplayText">
            <div class="vehicle__data-list-label">Seller</div>
            <div class="vehicle__data-list-value">{{ data.ConsignorName.DisplayText }}</div>
          </div>
          <div class="vehicle__data-list-item" v-if="data.LossType && data.LossType.DisplayText">
            <div class="vehicle__data-list-label">Loss Type</div>
            <div class="vehicle__data-list-value">{{ data.LossType.DisplayText }}</div>
          </div>
          <div class="vehicle__data-list-item" v-if="data.PrimaryDamage && data.PrimaryDamage.DisplayText">
            <div class="vehicle__data-list-label">Primary Damage</div>
            <div class="vehicle__data-list-value">{{ data.PrimaryDamage.DisplayText }}</div>
          </div>
          <div class="vehicle__data-list-item" v-if="data.SecondaryDamage && data.SecondaryDamage.DisplayText">
            <div class="vehicle__data-list-label">Secondary Damage</div>
            <div class="vehicle__data-list-value">{{ data.SecondaryDamage.DisplayText }}</div>
          </div>
          <div class="vehicle__data-list-item"
               v-if="data.Odometer && data.Odometer.Value && data.OdometerUOM && data.OdometerUOM.Value ">
            <div class="vehicle__data-list-label">ODO</div>
            <div class="vehicle__data-list-value">
              {{ data.Odometer.Value }}
              {{ data.OdometerUOM.Value }}
              {{ data.OdometerCondition && data.OdometerCondition.Value ? '(' + data.OdometerCondition.Value + ')' : '' }}
            </div>
          </div>
          <div class="vehicle__data-list-item"
               v-if="data.SaleDocType && data.SaleDocType.DisplayText && data.SaleDocState && data.SaleDocState.DisplayText">
            <div class="vehicle__data-list-label">Title/Sale Doc</div>
            <div class="vehicle__data-list-value">
              {{ data.SaleDocType.DisplayText + ' (' + data.SaleDocState.DisplayText + ')' }}
            </div>
          </div>
          <div class="vehicle__data-list-item"
               v-if="data.SaleDocNote && data.SaleDocNote.DisplayText">
            <div class="vehicle__data-list-label">TTitle/Sale Doc Note</div>
            <div class="vehicle__data-list-value">
              {{ data.SaleDocNote.DisplayText }}
            </div>
          </div>
        </div>
      </div>
      <div class="vehicle__item">
        <div class="vehicle__title">Announcements</div>
        <div class="vehicle__data-list">
          <div class="vehicle__data-list-item" v-if="data.BlackBookACV && data.BlackBookACV.DisplayText">
            <div class="vehicle__data-list-label">ACV</div>
            <div class="vehicle__data-list-value">{{ data.BlackBookACV.DisplayText }}</div>
          </div>
          <div class="vehicle__data-list-item" v-if="data.ECR && data.ECR.DisplayText">
            <div class="vehicle__data-list-label">Est. Repairs</div>
            <div class="vehicle__data-list-value">{{ data.ECR.DisplayText }}</div>
          </div>
          <div class="vehicle__data-list-item" v-if="data.Declarations && data.Declarations.DisplayText">
            <div class="vehicle__data-list-label">Declarations</div>
            <div class="vehicle__data-list-value">{{ data.Declarations.DisplayText }}</div>
          </div>
          <div class="vehicle__data-list-item" v-if="data.Airbags && data.Airbags.DisplayText">
            <div class="vehicle__data-list-label">Airbags</div>
            <div class="vehicle__data-list-value">{{ data.Airbags.DisplayText }}</div>
          </div>
          <div class="vehicle__data-list-item" v-if="data.Keys && data.Keys.DisplayText">
            <div class="vehicle__data-list-label">Keys</div>
            <div class="vehicle__data-list-value">{{ data.Keys.DisplayText }}</div>
          </div>
          <div class="vehicle__data-list-item" v-if="data.Starts && data.Starts.Value">
            <div class="vehicle__data-list-label">Starts Code</div>
            <div class="vehicle__data-list-value">{{ data.Starts.Value }}</div>
          </div>
          <div class="vehicle__data-list-item" v-if="data.Drives && data.Drives.Value">
            <div class="vehicle__data-list-label">Run & Drive</div>
            <div class="vehicle__data-list-value">{{ data.Drives.Value }}</div>
          </div>
        </div>
      </div>
      <div class="vehicle__item">
        <div class="vehicle__title">Vehicle Details</div>
        <div class="vehicle__data-list">
          <div class="vehicle__data-list-item" v-if="data.StockNumber && data.StockNumber.DisplayText">
            <div class="vehicle__data-list-label">Stock Number</div>
            <div class="vehicle__data-list-value">{{ data.StockNumber.DisplayText }}</div>
          </div>
          <div class="vehicle__data-list-item" v-if="data.VIN && data.VIN.DisplayText">
            <div class="vehicle__data-list-label">VIN</div>
            <div class="vehicle__data-list-value">{{ data.VIN.DisplayText }}</div>
          </div>
          <div class="vehicle__data-list-item" v-if="data.Transmission && data.Transmission.DisplayText">
            <div class="vehicle__data-list-label">Transmission</div>
            <div class="vehicle__data-list-value">{{ data.Transmission.DisplayText }}</div>
          </div>
          <div class="vehicle__data-list-item" v-if="data.Cylinder && data.Cylinder.DisplayText">
            <div class="vehicle__data-list-label">Cylinders</div>
            <div class="vehicle__data-list-value">{{ data.Cylinder.DisplayText }}</div>
          </div>
          <div class="vehicle__data-list-item" v-if="data.FuelType && data.FuelType.DisplayText">
            <div class="vehicle__data-list-label">Fuel Type</div>
            <div class="vehicle__data-list-value">{{ data.FuelType.DisplayText }}</div>
          </div>
          <div class="vehicle__data-list-item" v-if="data.Engine && data.Engine.DisplayText">
            <div class="vehicle__data-list-label">Engine</div>
            <div class="vehicle__data-list-value">{{ data.Engine.DisplayText }}</div>
          </div>
          <div class="vehicle__data-list-item" v-if="data.DriveTrainCode && data.DriveTrainCode.DisplayText">
            <div class="vehicle__data-list-label">Drive Line Type</div>
            <div class="vehicle__data-list-value">{{ data.DriveTrainCode.DisplayText }}</div>
          </div>
          <div class="vehicle__data-list-item" v-if="data.ExteriorColor && data.ExteriorColor.DisplayText">
            <div class="vehicle__data-list-label">Ext. Color</div>
            <div class="vehicle__data-list-value">{{ data.ExteriorColor.DisplayText }}</div>
          </div>
          <div class="vehicle__data-list-item" v-if="data.EntertainmentSystem && data.EntertainmentSystem.Value">
            <div class="vehicle__data-list-label">Ent. System</div>
            <div class="vehicle__data-list-value">{{ data.EntertainmentSystem.Value }}</div>
          </div>
          <div class="vehicle__data-list-item" v-if="data.Accessories && data.Accessories.Value">
            <div class="vehicle__data-list-label">Accessories</div>
            <div class="vehicle__data-list-value">{{ data.Accessories.Value }}</div>
          </div>
          <div class="vehicle__data-list-item" v-if="data.SeatsPresent && data.SeatsPresent.Value">
            <div class="vehicle__data-list-label">Seats Present</div>
            <div class="vehicle__data-list-value">{{ data.SeatsPresent.Value }}</div>
          </div>
          <div class="vehicle__data-list-item" v-if="data['Wheels/Tires'] && data['Wheels/Tires'].Value">
            <div class="vehicle__data-list-label">Wheels/Tires</div>
            <div class="vehicle__data-list-value">
              {{ data['Wheels/Tires'].Value }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "Vehicle",
  props: ['data']
}
</script>

<style lang="scss">
.vehicle {
  padding: 2rem 1rem 0;
  overflow-y: auto;
  height: 100%;
  word-break: break-word;

  &__location {
    padding: .5rem 1.5rem;
    border-radius: 3px;
    color: #574a2f;
    border: 1px solid #fac177;
    background: rgba(247,152,28,.2);
    font-weight: 600;
  }

  &__data-list {

    &-item {
      padding: .4rem 1rem;
      display: flex;

      &:nth-child(odd) {
        background-color: #f8fafc;
      }
    }

    &-label {
      width: 33%;
      color: #666;
      padding-right: 1rem;
    }

    &-value {
      font-weight: 700;
      color: #222;
      flex: 1;
    }
  }

  &__item {
    margin-bottom: 1rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #e8e8e8;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    font-weight: 700;
    padding-bottom: 1rem;
  }
}
</style>