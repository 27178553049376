const state = {
    auction_list: {},
    joined_auctions: {},
    auction_item: {},
    showPopup: false,
    lot: {},
    infoCurrentLot: {},
    id: null,
    spendingLimit: null,
    isConnectedClient: true,
    errorConnected: '',
    notifications: null,
}

import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
    state,
    actions,
    mutations,
    getters,
}