<template>
  <div class="run-list__header">
    <div class="run-list__close" @click="close"></div>
    <div class="run-list__header-title">
      <span class="icon icon--sm icon-runlist">
        <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path
          d="M39.086 20.667H19.2c-1.892 0-2.057 1.49-2.057 3.333 0 1.844.165 3.333 2.057 3.333h19.886c1.892 0 2.057-1.49 2.057-3.333 0-1.844-.165-3.333-2.057-3.333zm6.857 16.666H19.2c-1.892 0-2.057 1.49-2.057 3.334 0 1.843.165 3.333 2.057 3.333h26.743C47.835 44 48 42.51 48 40.667c0-1.844-.165-3.334-2.057-3.334zM19.2 10.667h26.743c1.892 0 2.057-1.49 2.057-3.334C48 5.49 47.835 4 45.943 4H19.2c-1.892 0-2.057 1.49-2.057 3.333 0 1.844.165 3.334 2.057 3.334zm-10.971 10H2.057C.165 20.667 0 22.157 0 24c0 1.844.165 3.333 2.057 3.333H8.23c1.892 0 2.057-1.49 2.057-3.333 0-1.844-.165-3.333-2.057-3.333zm0 16.666H2.057C.165 37.333 0 38.823 0 40.667 0 42.51.165 44 2.057 44H8.23c1.892 0 2.057-1.49 2.057-3.333 0-1.844-.165-3.334-2.057-3.334zM8.229 4H2.057C.165 4 0 5.49 0 7.333c0 1.844.165 3.334 2.057 3.334H8.23c1.892 0 2.057-1.49 2.057-3.334C10.286 5.49 10.12 4 8.229 4z"
          fill-rule="nonzero"></path>
        </svg>
      </span>
      RUN LIST
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: ['toggleView'],
  methods: {
    close() {
      this.toggleView(false);
    }
  }
}
</script>

<style lang="scss">
.run-list {
  $run: &;

  &--full {

    #{$run} {

      &__close {
        &:before {
          transform: rotate(-135deg);
          margin: 0 .6rem 0 0;
        }
      }
    }
  }

  &__header {
    display: flex;
    background: #506577;

    @media screen and (max-width: 767px) {
      display: none;
    }

    &-title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      color: #cbd2db;

      .icon {
        width: 1.5rem;
        margin: .2rem .5rem 0 0;
        vertical-align: top;
        fill: #cbd2db;
      }
    }
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.5rem;
    height: 3.5rem;
    border-right: 1px solid #b2bcc8;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
      background: #384b5d;
    }

    &:before {
      content: '';
      padding: 5px;
      border: 2px solid #fff;
      border-top: 0;
      border-right: 0;
      margin-left: 0.6rem;
      transform: rotate(45deg);
      transition: all .2s ease-in;
    }
  }
}
</style>