import IaaiEventBus from "@/events";
import {AUCTION_EVENT_IAAI, IAAI_EVENT_AUCTION_JOINED, IAAI_EVENT_LEAVE_AUCTION_CONFIRMATION} from "@/consts";
import store from "@/store";
import {SET_CONNECTED_CLIENT, SET_ERROR_CONNECTED} from "@/store/mutations.type";
const AuctionNow = require('./assets/js/AuctionNow')

class IaaiService {

    constructor() {
        this.iaaiEventBus = null;
        this.iaaiClient = null;
    }

    /**
     *
     * @param {string} bearerToken
     */
    init(bearerToken) {
        if(bearerToken) {
            const configuration = {
                "portalApiUrl": "https://apiportal.auctionnow.iaai.com/api/",
                "systemHubUrl": "https://portal.auctionnow.iaai.com",
                "bearerToken": bearerToken,
            }

            this.iaaiClient = new AuctionNow.Client(configuration);
            const iaaiEventBusInit = new IaaiEventBus
            this.iaaiEventBus = iaaiEventBusInit.init(this.iaaiClient, this)
        } else {
            store.commit(SET_CONNECTED_CLIENT, false);
            store.commit(SET_ERROR_CONNECTED, 'An error has occurred. Try again.');
        }
    }

    /**
     * join to auction
     *
     * @param {string} InternalAuctionId
     * @param {string} ExternalAuctionId
     * @param {function} successCallback
     * @param {function} failCallback
     */
    joinToAuction(InternalAuctionId, ExternalAuctionId, successCallback, failCallback) {
        /**
         * event subscription
         */
        this.iaaiEventBus.$on(AUCTION_EVENT_IAAI + InternalAuctionId, async function (payload) {
            switch (payload.iaaiEvent) {
                case IAAI_EVENT_AUCTION_JOINED:
                    successCallback(false)
                    break
                case IAAI_EVENT_LEAVE_AUCTION_CONFIRMATION:
                    successCallback(true)
                    break
                default:
                    failCallback(new Error("Unexpected iaai event: " + payload.iaaiEvent))
                    break
            }
        });

        this.iaaiClient.join("iaai", ExternalAuctionId);
    }

    /**
     *
     * @param {string} InternalAuctionId
     * @param {function} successCallback
     */
    leaveAuction(InternalAuctionId, successCallback) {
        this.iaaiClient.leave(InternalAuctionId)
            .then(function () {
                successCallback();
            })
            .catch(function (error) {
                console.log('error', error)
            })
    }
}

export default IaaiService
