<template>
  <div class="loader">
    <div class="loader__main"></div>
  </div>
</template>

<script>
export default {
name: "loader"
}
</script>

<style lang="scss">
.loader {
  display: flex;
  justify-content: center;

  &__main {
    border: .3rem solid #f3f3f3;
    border-top-color: #c90008;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    animation: spin 1.5s linear infinite;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>