<template>
  <div class="run-list__filter">
    <div class="run-list__filter-wrap">
      <button class="btn btn--xs" :class="{'is-active': activeList === 'all'}" @click="click" data-name="all">ALL ({{allLength}})</button>
      <button class="btn btn--xs" :class="{'is-active': activeList === 'watchlist'}" @click="click" data-name="watchlist">MY VEHICLES ({{watchListLength}})</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['allLength', 'watchListLength', 'activeList', 'modifyActiveList'],
  name: "Filters",
  methods: {
    click(e) {
      this.modifyActiveList(e.target.dataset.name);
    }
  }
}
</script>

<style lang="scss">
.run-list {

  &__filter {
    padding: 1rem;
    border-bottom: 1px solid #ccc;

    &-wrap {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      .btn {
        width: 50%;
        border: 1px solid #ccc;
        background-color: #f8f8f8;

        &:hover {
          background-image: linear-gradient(0deg, #f8f8f8, #f2f2f2);
        }

        &.is-active {
          cursor: default;
          color: #fff;
          border: 1px solid #0c73c1;
          background-image: linear-gradient(0deg, #1991eb, #2ea1f8);
          text-shadow: 0 2px 1px rgba(0, 0, 0, .3);
          pointer-events: none;
        }

        &:first-child {
          border-radius: 4px 0 0 4px;
        }

        &:last-child {
          border-radius: 0 4px 4px 0;
        }
      }
    }
  }
}
</style>