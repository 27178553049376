<template>
  <button class="main-nav__link" :class="{'js-active': isActive}" @click="showPanel">
  <span class="icon icon--sm icon-list-right">
    <slot name="icon"></slot>
  </span>
    <span class="main-nav__link-label">{{ text }}</span>
  </button>
</template>

<script>
export default {
  name: "ItemLink",
  props: ['isActive', 'activeText', 'text', 'changeActiveBlock'],
  methods: {
    showPanel() {
      this.changeActiveBlock(this.activeText);
    }
  }
}
</script>

<style lang="scss">
@media only screen and (min-width: 1250px) {
  .main-nav__link {
    font-size: 1.2rem;
    width: 100%;
    min-height: 7rem;
    text-align: center;
    color: #fff;
    border-left: 3px solid transparent;
    border-bottom: 1px solid #999;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    transition: all .2s ease-in-out;

    &.js-active {
      border-color: #c90107;
      background: #525252;
      border-bottom: 1px solid #999;
    }

    &:hover {
      background: #525252
    }

    .icon {
      fill: #fff
    }

    &--negotiation {
      position: relative;

      .icon {
        width: 2.3rem;
        height: 2.3rem
      }
    }

    &-label {
      display: block
    }
  }
}

@media screen and (max-width: 1249px) {
  .main-nav__link {
    display: flex;
    align-items: center;
    padding: 0;

    .icon {
      fill: #000;
    }

    &-label {
      padding-left: 1rem;
      font-weight: 700;
      color: #000;
      text-shadow: none;
    }
  }

}
</style>