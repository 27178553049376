<template>
  <div class="panel__header">
    <h5>{{title}}</h5>
    <div class="main-nav__close" @click="closePanel">
      <span class="icon"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderSidebarNavigation",
  props: ['title', 'changeActiveBlock'],
  methods: {
    closePanel() {
      this.changeActiveBlock('');
    }
  }
}
</script>

<style lang="scss">
.main-nav__close {
  width: 3.5rem;
  height: 3.5rem;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all .2s ease;

  .icon {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0 auto;
  }
}

.panel__header {
  padding-left: 1rem;
  border-bottom: 1px solid #666;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 3.5rem;

  h5 {
    margin-right: 1rem;
    margin-bottom: 0
  }

  .main-nav__close, .dropdown--event-header > button {
    border-bottom: 1px solid #666;
    border-left: 1px solid #666
  }

  .main-nav__close:hover, .dropdown--event-header > button:hover {
    background: #525252
  }

  .main-nav__close .icon, .dropdown--event-header > button .icon {
    background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 17.165L41.088.075l6.836 6.837L30.834 24l17.09 17.088-6.836 6.836L24 30.834 6.912 47.925.076 41.088 17.166 24 .075 6.912 6.912.076 24 17.166z' fill='%23FFF' fill-rule='evenodd'/%3E%3C/svg%3E") no-repeat 0 0
  }
}
</style>