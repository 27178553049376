<template>
  <nav class="main-nav theme--dark">
    <Navigation :activeBlock="activeBlock" :changeActiveBlock="changeActiveBlock"/>
    <SidebarContent :activeBlock="activeBlock" :changeActiveBlock="changeActiveBlock"/>
  </nav>
</template>

<script>
import Navigation from "@/components/Sidebar/Navigation";
import SidebarContent from "@/components/Sidebar/SidebarContent";

export default {
  name: "Sidebar",
  components: {
    Navigation,
    SidebarContent
  },
  data: function () {
    return {
      activeBlock: ''
    }
  },
  methods: {
    changeActiveBlock(name) {
      this.activeBlock = name;
    }
  }
}
</script>

<style lang="scss">
.main-nav {
  @media only screen and (min-width: 640px) {
    z-index: 991;
    height: auto;
    transition: flex-basis .5s ease;
    background: none;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex: 1 0 8rem;
    order: 0
  }

  @media screen and (max-width: 1249px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 994;
    padding: 1rem;
    border-top: 2px solid #D9D9D9;
  }
}
</style>