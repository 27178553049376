<template>
  <div class="panel-auction">
    <SidebarPanelAuctionItem v-for="(auction, id) in auctionList" :auction="auction" v-bind:key="id"/>
  </div>
</template>

<script>
import SidebarPanelAuctionItem from "@/components/Sidebar/SidebarPanelAuctionItem";

export default {
  data: function () {
    return {
      auctions: [],
    }
  },
  name: "SidebarPanelAuction",
  components: {
    SidebarPanelAuctionItem
  },
  computed: {
    auctionList() {
      return this.$store.state.auctions.auction_list
    }
  }
}
</script>

<style lang="scss">
.panel {

  &-auction {
    border-top: 1px solid #fff;
    flex: 1;
  }
}
</style>