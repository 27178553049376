<template>
  <div class="auction-container__header">
    <div class="auction-container__event-name">{{ name }}</div>
    <div class="auction-container__event-lane">Lane {{ lane }}</div>
    <div class="auction-container__notifications">
      <label :for="`notifications_${auctionId}`" class="checkbox">
        <input type="checkbox" :id="`notifications_${auctionId}`" v-model="getChecked" class="checkbox__input">
        <span class="checkbox__text"></span>
      </label>
      <div class="auction-container__notifications-text">Notifications</div>
    </div>

    <div class="auction-container__close" @click="closeContainer">
      <span class="icon"></span>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {SET_NOTIFICATIONS} from "@/store/mutations.type";

export default {
  props: ['name', 'lane', 'auctionId'],
  name: "AuctionContainerHeader",
  methods: {
    ...mapMutations({setNotifications: SET_NOTIFICATIONS}),
    closeContainer() {
      this.$emit('auctionContainerClose', {InternalAuctionId: this.auctionId})
    },
  },
  computed: {
    ...mapGetters(['getNotifications']),
    getChecked: {
      get() {
        return this.getNotifications === this.auctionId;
      },
      set(value) {
        if(value) {
          this.setNotifications({InternalAuctionId: this.auctionId});
        } else {
          this.setNotifications();
        }
      }
    },
  }
}
</script>

<style lang="scss">
.auction-container {

  &__header {
    display: flex;
    align-items: center;
    background: #fff;
    padding-left: .7rem;
  }

  &__close {
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    cursor: pointer;

    .icon {
      filter: invert(1);
      width: 1.2rem;
      height: 1.2rem;
      background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 17.165L41.088.075l6.836 6.837L30.834 24l17.09 17.088-6.836 6.836L24 30.834 6.912 47.925.076 41.088 17.166 24 .075 6.912 6.912.076 24 17.166z' fill='%23FFF' fill-rule='evenodd'/%3E%3C/svg%3E") no-repeat 0 0;
    }
  }

  &__event {

    &-name {
      font-weight: 600;
    }

    &-lane {
      font-weight: 600;
      display: inline-block;
      margin-right: .5rem;
      margin-left: .5rem;
      padding: .2rem 1rem;
      border-radius: 2rem;
      background: #ceebef;
      line-height: 1.2;
    }
  }

  &__notifications {
    display: flex;
    align-items: center;
    margin-left: auto;

    &-text {
      padding-left: 1rem;
    }
  }
}
</style>