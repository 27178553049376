import {
    SET_AUCTIONS,
    ADD_JOIN_AUCTION,
    REMOVE_JOIN_AUCTION,
    SET_AUCTION_ITEM,
    SHOW_POPUP,
    SET_LOT,
    AUCTION_START,
    UPDATE_BID_HISTORY,
    UPDATE_CURRENT_BID_LOT,
    UPDATE_MESSAGE_LOT,
    UPDATE_STATUS_AUCTION,
    UPDATE_CURRENT_HIGH_BID_LOT,
    SET_MAX_AUTO_BID,
    ADDED_WATCHLIST,
    RESET_AUTO_BID,
    SET_USER_ID,
    SET_BIDDING_STATE,
    SET_SPENDING_LIMIT,
    SET_BIDDING_ERROR,
    RESULT_BIDDING,
    UPDATE_CURRENT_LOT,
    RESET_BID_HISTORY,
    SET_BIDDING_STARTING,
    SET_CONNECTED_CLIENT,
    SET_ERROR_CONNECTED, SET_NOTIFICATIONS,
} from "@/store/mutations.type";
import Vue from 'vue'

const mutations = {
    [SET_AUCTIONS](state, auctions) {
        var list = {}

        auctions.forEach((item) => {
            list[item.InternalAuctionId] = item
        });

        state.auction_list = list
    },
    [ADD_JOIN_AUCTION](state, internalAuctionId) {
        state.joined_auctions = {...state.joined_auctions, [internalAuctionId]: true}
    },
    [REMOVE_JOIN_AUCTION](state, internalAuctionId) {
        Vue.delete(state.joined_auctions, internalAuctionId)
    },
    [SET_AUCTION_ITEM](state, payload) {
        var list = {};

        payload.response.forEach(item => {
            list[item.InternalAuctionItemId] = item;
        });
        state.auction_item = {...state.auction_item, [payload.InternalAuctionId]: list}

    },
    [SHOW_POPUP](state, value) {
        state.showPopup = value;
    },
    [SET_LOT](state, lot) {
        if (lot.delete === true) {
            Vue.delete(state.lot, lot.InternalAuctionId);
        } else {
            state.lot = {...state.lot, [lot.InternalAuctionId]: lot};
            state.auction_list[lot.InternalAuctionId] = {...state.auction_list[lot.InternalAuctionId], currentLot: lot}
        }

    },
    [AUCTION_START](state, InternalAuctionId) {
        var auction = state.auction_list[InternalAuctionId]
        auction.auctionStarted = true

        state.auction_list[InternalAuctionId] = Object.assign({}, auction)
    },
    [UPDATE_BID_HISTORY](state, payload) {
        const {InternalAuctionId} = payload;
        const history = state.infoCurrentLot?.[InternalAuctionId]?.bidHistory || [];
        payload.bidHistory.timestamp = new Date()
        history.push(payload.bidHistory);

        state.infoCurrentLot = {
            ...state.infoCurrentLot,
            [InternalAuctionId]: {
                ...state.infoCurrentLot[InternalAuctionId],
                bidHistory: history
            }
        }
    },
    [UPDATE_CURRENT_HIGH_BID_LOT](state, payload) {
        const {InternalAuctionId} = payload;

        state.infoCurrentLot = {
            ...state.infoCurrentLot,
            [InternalAuctionId]: {
                ...state.infoCurrentLot[InternalAuctionId],
                currentHighBid: {
                    amount: payload.Amount,
                    display: payload.Display,
                    bidder: payload.Bidder
                }
            }
        }
    },
    [UPDATE_CURRENT_BID_LOT](state, payload) {
        const {InternalAuctionId} = payload;
        state.infoCurrentLot = {
            ...state.infoCurrentLot,
            [InternalAuctionId]: {
                ...state.infoCurrentLot[InternalAuctionId],
                currentBid: payload.Amount
            }
        }
    },
    [UPDATE_MESSAGE_LOT](state, payload) {
        const {InternalAuctionId} = payload;
        const message = state.infoCurrentLot?.[InternalAuctionId]?.message || [];
        state.infoCurrentLot = {
            ...state.infoCurrentLot,
            [InternalAuctionId]: {
                ...state.infoCurrentLot[InternalAuctionId],
                message: [
                    ...message,
                    payload.message
                ]
            }
        }
    },
    [UPDATE_STATUS_AUCTION](state, payload) {
        state.auction_list[payload.InternalAuctionId].Status = payload.Status;
    },
    [SET_MAX_AUTO_BID](state, payload) {
        state.auction_item = {
            ...state.auction_item, [payload.InternalAuctionId]: {
                ...state.auction_item[payload.InternalAuctionId],
                [payload.InternalAuctionItemId]: {
                    ...state.auction_item[payload.InternalAuctionId][payload.InternalAuctionItemId],
                    maxAutoBid: payload.MaxAutoBid,
                    currentHighBidAmount: payload.CurrentHighBidAmount,
                }
            }
        }
    },
    [SET_BIDDING_STATE](state, payload) {
        state.auction_item = {
            ...state.auction_item, [payload.InternalAuctionId]: {
                ...state.auction_item[payload.InternalAuctionId],
                [payload.InternalAuctionItemId]: {
                    ...state.auction_item[payload.InternalAuctionId]?.[payload.InternalAuctionItemId] || {},
                    biddingState: payload.BiddingState
                }
            }
        }
    },
    [RESET_AUTO_BID](state, payload) {
        state.lot = {
            ...state.lot, [payload.InternalAuctionId]: {
                ...state.lot[payload.InternalAuctionId],
                AutoBid: null
            }
        }
    },
    [ADDED_WATCHLIST](state, payload) {
        state.auction_item = {
            ...state.auction_item, [payload.InternalAuctionId]: {
                ...state.auction_item[payload.InternalAuctionId],
                [payload.InternalAuctionItemId]: {
                    ...state.auction_item[payload.InternalAuctionId][payload.InternalAuctionItemId],
                    watchlist: payload.Value
                }
            }
        }
    },
    [SET_USER_ID](state, id) {
        state.id = id;
    },
    [SET_SPENDING_LIMIT](state, limit) {
        state.spendingLimit = limit;
    },
    [SET_BIDDING_ERROR](state, payload) {
        state.auction_item = {
            ...state.auction_item, [payload.InternalAuctionId]: {
                ...state.auction_item[payload.InternalAuctionId],
                [payload.InternalAuctionItemId]: {
                    ...state.auction_item[payload.InternalAuctionId][payload.InternalAuctionItemId],
                    biddingError: payload.BiddingError
                }
            }
        }
    },
    [RESULT_BIDDING](state, payload) {
        state.auction_item = {
            ...state.auction_item, [payload.InternalAuctionId]: {
                ...state.auction_item[payload.InternalAuctionId],
                [payload.InternalAuctionItemId]: {
                    ...state.auction_item[payload.InternalAuctionId][payload.InternalAuctionItemId],
                    resultBidding: payload.ResultBidding
                }
            }
        }
    },
    [UPDATE_CURRENT_LOT](state, payload) {
        const {InternalAuctionId} = payload;
        if (payload.delete === true) {
            Vue.delete(state.infoCurrentLot, InternalAuctionId);
        } else {
            state.infoCurrentLot = {
                ...state.infoCurrentLot,
                [InternalAuctionId]: {
                    ...state.infoCurrentLot[InternalAuctionId],
                    ...payload.lot
                }
            }
        }
    },
    [RESET_BID_HISTORY](state, payload) {
        const {InternalAuctionId} = payload;

        state.infoCurrentLot = {
            ...state.infoCurrentLot,
            [InternalAuctionId]: {
                ...state.infoCurrentLot[InternalAuctionId],
                bidHistory: null
            }
        }
    },
    [SET_BIDDING_STARTING](state, payload) {
        state.auction_item = {
            ...state.auction_item, [payload.InternalAuctionId]: {
                ...state.auction_item[payload.InternalAuctionId],
                [payload.InternalAuctionItemId]: {
                    ...state.auction_item[payload.InternalAuctionId][payload.InternalAuctionItemId],
                    biddingStarting: payload.BiddingStarting
                }
            }
        }
    },
    [SET_CONNECTED_CLIENT](state, value) {
        state.isConnectedClient = value;
    },
    [SET_ERROR_CONNECTED](state, text) {
        state.errorConnected = text;
    },
    [SET_NOTIFICATIONS](state, payload) {
        state.notifications = payload?.InternalAuctionId || null;
    }
}

export default mutations;