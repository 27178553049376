<template>
  <div class="current-vehicle">
    <div v-if="isWatchList" class="current-vehicle__favorite" @click="WatchListToggleHandler">
      <img :src="'/assets/img/icons/favorite' + (getWatchListItem ? '-active' : '') + '.svg'" alt="">
    </div>
    <div class="current-vehicle__number">{{ lot.SequenceNumber }}</div>
    <div class="current-vehicle__wrap" v-if="lot.Attributes">
      <div class="current-vehicle__header">
        <div class="current-vehicle__stock-number" v-if="lot.Attributes.StockNumber && lot.Attributes.StockNumber.Value">
          Stock #: <a :href="`https://sca.auction/en/${lot.Attributes.StockNumber.Value}-xxxx-xxx-xxxxx`">
          {{ lot.Attributes.StockNumber.Value }}</a>
        </div>
        <div class="badge badge--xs badge--ol badge--success" v-if="lot.Attributes.Drives && lot.Attributes.Drives.Value === 'yes'">Drives</div>
        <div class="badge badge--xs badge--ol badge--warning" v-if="lot.Attributes.Location && lot.Attributes.Location.Value">Offsite</div>
      </div>
      <div class="current-vehicle__main">
        <span v-if="lot.Attributes.Year && lot.Attributes.Year.Value">{{ lot.Attributes.Year.Value }}</span>
        <span v-if="lot.Attributes.Make && lot.Attributes.Make.Value"> {{ lot.Attributes.Make.Value }}</span>
        <span v-if="lot.Attributes.Model && lot.Attributes.Model.Value"> {{ lot.Attributes.Model.Value }}</span>
        <span v-if="lot.Attributes.Series && lot.Attributes.Series.Value"> {{ lot.Attributes.Series.Value }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CurrentVehicle",
  props: ['lot', 'isWatchList'],
  computed: {
    ...mapGetters(['watchListItem', 'userId']),
    getWatchListItem() {
      return this.watchListItem({
        InternalAuctionId: this.lot.InternalAuctionId,
        InternalAuctionItemId: this.lot.InternalAuctionItemId
      })
    },
    getUserId() {
      return this.userId;
    }
  },
  methods: {
    /**
     * Watchlist toggle handler
     *
     * @returns {void}
     */
    WatchListToggleHandler() {
      if (this.getWatchListItem) {
        this.$iaaiService.iaaiClient.removeFromWatchList(this.lot.InternalAuctionId, this.lot.InternalAuctionItemId, this.getUserId);
      } else {
        this.$iaaiService.iaaiClient.addToWatchList(this.lot.InternalAuctionId, this.lot.InternalAuctionItemId, this.getUserId);
      }
    }
  }
}
</script>

<style lang="scss">
.current-vehicle {
  display: flex;
  align-items: center;
  padding-right: 1rem;

  &__header {
    display: flex;
    align-items: center;
  }

  &__number {
    min-width: 2.5rem;
    color: #fff;
    border-radius: 2rem;
    background: #008c99;
    font-size: 1.5rem;
    font-weight: 700;
    text-shadow: 0 2px 1px rgba(0, 0, 0, .2);
    padding: 0 .5rem;
    line-height: 1.7;
    margin: 0 .5rem;
    text-align: center;
  }

  &__stock-number {
    font-size: 1.2rem;
    font-weight: 600;
    color: #666;
    margin-right: .5rem;
  }

  &__main {
    font-weight: 700;
  }

  &__wrap {
    padding: .5rem 0;
    flex: 1;
  }

  &__favorite {
    height: 2.5rem;
    width: 2.5rem;
    margin-left: .5rem;
    cursor: pointer;
  }
}
</style>