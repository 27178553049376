<template>
  <div class="main-nav__links">
    <ItemLink v-slot:icon :isActive="activeBlock === 'Auctions'" :changeActiveBlock="changeActiveBlock"
              activeText="Auctions" text="Auction list">
      <svg viewBox="0 0 48 47" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M44.138 3.31h-4.966v4.966h4.966V3.31zm-8.276-1.379C35.862.865 36.727 0 37.793 0h8.276C47.135 0 48 .865 48 1.931v8.276a1.931 1.931 0 0 1-1.931 1.93h-8.276a1.931 1.931 0 0 1-1.93-1.93V1.93zm8.276 36.525h-4.966v4.414h4.966v-4.414zm-8.276-1.931c0-1.067.865-1.931 1.931-1.931h8.276c1.066 0 1.931.864 1.931 1.93v8.277a1.931 1.931 0 0 1-1.931 1.93h-8.276a1.931 1.931 0 0 1-1.93-1.93v-8.276zm8.276-15.008h-4.966v4.414h4.966v-4.414zm-8.276-1.93c0-1.067.865-1.932 1.931-1.932h8.276c1.066 0 1.931.865 1.931 1.931v8.276a1.931 1.931 0 0 1-1.931 1.931h-8.276a1.931 1.931 0 0 1-1.93-1.93v-8.277zM2.76 8.826h24.157a2.759 2.759 0 0 0 0-5.517H2.759a2.759 2.759 0 0 0 0 5.518zm0 17.656h24.157a2.759 2.759 0 0 0 0-5.517H2.759a2.759 2.759 0 0 0 0 5.517zm26.915 14.896a2.759 2.759 0 0 1-2.758 2.759H2.759a2.759 2.759 0 0 1 0-5.517h24.157a2.759 2.759 0 0 1 2.758 2.758z"
            fill-rule="evenodd"></path>
      </svg>
    </ItemLink>
    <div class="main-nav__link">
      <label for="music" class="checkbox">
        <input type="checkbox" id="music" v-model="audioChecked" class="checkbox__input">
        <span class="checkbox__text">Music</span>
      </label>
    </div>
    <!--    <ItemLink v-slot:icon :isActive="activeBlock === 'Manage'" activeText="Manage" :activeBlock="activeBlock" :changeActiveBlock="changeActiveBlock" text="Manage Offers">-->
    <!--      <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">-->
    <!--        <path d="M43.2 0l.213.005c2.54.112 4.563 2.226 4.563 4.795L48 48l-9.6-9.6H4.8l-.213-.005C2.045 38.283 0 36.17 0 33.6V4.8l.005-.213C.117 2.045 2.23 0 4.8 0h38.4zm0 4.8H4.8v28.8h35.592l2.808 2.808V4.8zM25.408 8.29v2.655c2.489.619 3.74 2.534 3.822 4.619H26.6c-.071-1.516-.857-2.546-2.977-2.546-2.012 0-3.215.921-3.215 2.243 0 1.151.87 1.89 3.573 2.606 2.702.715 5.596 1.89 5.596 5.333 0 2.485-1.846 3.855-4.168 4.303v2.606h-3.572v-2.63c-2.286-.497-4.239-1.988-4.381-4.643h2.62c.13 1.43 1.094 2.546 3.547 2.546 2.632 0 3.215-1.334 3.215-2.17 0-1.127-.595-2.194-3.572-2.921-3.322-.812-5.596-2.206-5.596-5.006 0-2.34 1.857-3.867 4.167-4.376V8.291h3.572z" fill-rule="nonzero"></path>-->
    <!--      </svg>-->
    <!--    </ItemLink>-->
  </div>
</template>

<script>
import ItemLink from './NavigationItem'

export default {
  props: ['activeBlock', 'changeActiveBlock'],
  name: "Navigation",
  components: {
    ItemLink
  },
  data: function () {
    return {
      audio: null,
      audioChecked: false,
    }
  },
  mounted() {
    this.audio = new Audio('/assets/audio/background.mp3');
    this.audio.loop = true;
  },
  watch: {
    audioChecked() {
      if (this.audioChecked) {
        this.audio.play();
      } else {
        this.audio.pause();
      }
    }
  },
}
</script>

<style lang="scss">
.main-nav__links {
  height: 100%;

  @media only screen and (min-width: 1250px) {
    z-index: 992;
    width: 8rem;
    text-align: center;
    color: #fff;
    background: linear-gradient(180deg, #666 0, #999);
    box-shadow: -1px 5px 15px 0 rgba(0, 0, 0, .75)
  }

  @media screen and (max-width: 1249px) {
    display: flex;
    justify-content: space-between;
  }

  .checkbox {

    &__text {

      &:before {
        margin-bottom: .5rem;
      }

      @media screen and (max-width: 1249px) {
        flex-direction: row;
        color: #000;
        text-shadow: none;

        &:before {
          order: 1;
          margin: 0 0 0 1rem;
        }

        &:after {
          left: unset;
          right: 3px;
        }
      }
    }

    &__input {
      @media screen and (max-width: 1249px) {

        &:checked {

          & + .checkbox__text:after {
            right: 19px;
            left: unset;
          }
        }
      }
    }
  }
}

.checkbox {
  margin: 0;

  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 1rem 0 0 2rem;

    &:checked {

      & + .checkbox__text {

        &:before {
          background: #C20005;
        }

        &:after {
          background: #FFFFFF;
          left: 50%;
        }
      }
    }
  }

  &__text {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:before {
      content: '';
      background: #FFFFFF;
      border: 1px solid #999;
      border-radius: 200px;
      width: 3.8rem;
      height: 2.2rem;
      transition: all .2s ease-in-out;
    }

    &:after {
      content: '';
      position: absolute;
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
      background: #CECECE;
      top: 3px;
      left: calc(50% - 1.6rem);
      transition: all .2s ease-in-out;
    }
  }
}
</style>