<template>
  <div class="info__main">
    <template v-if="getMessage">
      <div class="info__title">Messages</div>
      <div class="info__message">
        <div class="info__message-item" v-for="(item, index) in getMessage" :key="index">Run
          #{{ item.sequenceNumber }}: Price {{ item.currentBid ? '$' + item.currentBid : '' }} {{ item.text }}
        </div>
      </div>
    </template>
    <template v-if="history">
      <div class="info__title">Bid History</div>
      <div class="info__history">
        <template v-for="(item, index) in getSortedHistory">
          <div class="info__history-item" :key="index">{{item.Text}}</div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  name: "InfoMain",
  props: ['auctionId', 'history', 'message'],
  computed: {
    getMessage() {
      return this.message?.length ? this.message.slice(-2).reverse() : null;
    },
    getSortedHistory() {
      let newHistory = this.history;

      newHistory.sort(function(a, b) {
        if (a.timestamp < b.timestamp) return 1;
        if (a.timestamp > b.timestamp) return -1;
        return 0;
      });


      return newHistory
    }
  },
}
</script>

<style scoped>

</style>