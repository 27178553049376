<template>
    <VueSlickCarousel class="slider" v-if="images && images.length" v-bind="settings">
      <template v-for="item in images">
        <img :src="item.FullUri" :alt="item.Description" :key="item.FullUri" class="slider__img">
      </template>
    </VueSlickCarousel>
  <div v-else>Images not found</div>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
  name: "Slider",
  props: ['images'],
  components: {
    VueSlickCarousel,
  },
  data: function () {
    return {
      settings: {
        autoplay: true,
        speed: 800
      },
    }
  },
}
</script>

<style lang="scss">
.slider {

  &__img {
    width: 100%;
    max-width: 36rem;
    margin: 0 auto;
    display: block !important;
  }

  &.slick-slider {
    overflow: hidden;
    border-bottom: 1px solid #ccc;

    &:hover {

      .slick {

        &-arrow {
          opacity: 1;
          z-index: 100;
        }

        &-next,
        &-prev {
          transform: none;
        }
      }
    }

    .slick {

      &-arrow {
        font-size: 0;
        position: absolute;
        width: 3rem;
        height: 5rem;
        top: 50%;
        background-color: rgba(20, 41, 57, .8);
        margin-top: -2.5rem;
        z-index: -1;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: all .2s ease-in-out;

        &:hover {
          background-color: rgba(20, 41, 57, .9);
        }

        &:before {
          content: '';
          border: .2rem solid #fff;
          border-top: 0;
          border-right: 0;
          padding: 4px;
          display: inline-block;
        }
      }

      &-next {
        right: 0;
        transform: translateX(100%);

        &:before {
          transform: rotate(-135deg);
        }
      }

      &-prev {
        left: 0;
        transform: translateX(-100%);

        &:before {
          transform: rotate(45deg);
        }
      }
    }
  }
}
</style>